import actions from '@/store/benchmark/actions';
import getters from '@/store/benchmark/getters';
import mutations from '@/store/benchmark/mutations';
import { RootState } from '@/store/benchmark/interfaces';

const benchmarkFilters = {
  from: '',
  to: '',
  auswahlBankkategorie: [],
  allAuswahlBankkategorie: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
  ],
  bankType: [],
  konto: ['grundpreis', 'dispozins'],
  transaktion: [],
};

export function defaultState() {
  return {
    error: false,
    loading: false,
    loadingDates: false,
    availableDates: [],
    selectedDates: {
      from: '',
      to: '',
    },
    benchmarkFilters,
    copyBenchmarkFilters: benchmarkFilters,
    benchmarkData: {
      mittelwerte: [],
      mittelwerte_b: [],
    },
    snackbarData: {
      show: false,
      text: '',
      timeout: 0,
      color: '',
      dark: true,
    },
  };
}
const state: RootState = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
