import { WETTBEWERB_SETTINGS } from '@/utils/constants';

const filters = {
  anlagebetrag(value: number, digits?: number) {
    return (
      value.toLocaleString('de-DE', {
        currency: 'EUR',
        minimumFractionDigits: digits,
      }) +
      ' ' +
      WETTBEWERB_SETTINGS.euroZeichen
    );
  },
  prozent(value: number, digits: number) {
    return (
      value.toLocaleString('de-DE', {
        minimumFractionDigits: digits,
      }) +
      ' ' +
      WETTBEWERB_SETTINGS.prozentZeichen
    );
  },
  zeitraumJahre(value: number) {
    if (value === 0) {
      return 'Von - bis';
    } else if (value === 1) {
      return `${value} Jahr`;
    } else {
      return `${value} Jahre`;
    }
  },
  periodYears(value: number) {
    if (value === 0) {
      return 'From - to';
    } else if (value === 1) {
      return `${value} Year`;
    } else {
      return `${value} Year`;
    }
  },
  kundenkreis(value: number) {
    if (value === 0) {
      return 'Neu- und Bestandskunden';
    } else if (value === 1) {
      return 'nur Neukunden';
    } else if (value === 2) {
      return 'nur Bestandskunden';
    }
  },
  clientele(value: number) {
    if (value === 0) {
      return 'New and existing customer offers';
    } else if (value === 1) {
      return 'New customer offers only';
    } else if (value === 2) {
      return 'Only existing customer offers';
    }
  },
  vermittlerangebote(value: number) {
    if (value === 0) {
      return 'Nein';
    } else if (value === 1) {
      return 'Ja';
    } else if (value === 2) {
      return 'Nur Vermittlerangebote';
    }
  },
  datum(d: Date) {
    return d.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  },
};

export default filters;
