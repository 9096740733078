import { updateField } from 'vuex-map-fields';
import { checkedCheckBoxes } from '@/utils/helpers/wettbwerb';
import { defaultState } from './index';
import {
  ALL_REPORTS,
  FORM_PARAMS_BAUGELD,
  FORM_PARAMS_FESTGELD,
  FORM_PARAMS_TAGESGELD,
} from '@/utils/constants';

const REPORTS = {
  tagesgeld: 'allReportsTagesgeld',
  festgeld: 'allReportsFestgeld',
  baugeld: 'allReportsBaugeld',
};
function setDefaultForm(reportType) {
  const result = {};
  if (reportType === 4) {
    Object.assign(result, FORM_PARAMS_TAGESGELD);
  }
  if (reportType === 5) {
    Object.assign(result, FORM_PARAMS_FESTGELD);
  }
  if (reportType === 6) {
    Object.assign(result, FORM_PARAMS_BAUGELD);
  }
  return result;
}

function modifyReports(reports, payload) {
  return reports.map((item) =>
    item.key === payload.key ? { ...item, ...payload } : item
  );
}

function modifyFormData(defaultFormParams, payload) {
  const result = defaultFormParams;
  if (payload.hasOwnProperty('vermittlerangebote')) {
    if (payload.vermittlerangebote === 0) {
      Object.assign(result, payload, {
        vermittlerangebote: { value: 0, selected: false },
      });
    } else {
      Object.assign(result, payload, {
        vermittlerangebote: { value: 1, selected: true },
      });
    }
  } else {
    Object.assign(result, payload);
  }
  return result;
}
export default {
  updateField,
  loading(state, payload) {
    state.loading = payload;
  },
  error(state, payload) {
    state.error = payload;
  },
  loadingAllReports(state, payload) {
    state.loadingAllReports = { ...state.loadingAllReports, ...payload };
  },
  updateReports(state, payload) {
    const { type, result } = payload;
    state[REPORTS[type]] = [result, ...state[REPORTS[type]]];
    state.loading = false;
  },
  updateReport(state, payload) {
    const { type, result } = payload;
    state[REPORTS[type]] = state[REPORTS[type]].map((item) =>
      item.slug === result.slug ? result : item
    );
  },
  setValueMaxAvgMin(state, payload) {
    const { param, data } = payload;
    const banksData = {
      bankname1: param.bankname1,
      bankname2: param.bankname2,
      bankname3: param.bankname3,
      bankname4: param.bankname4,
      bankname5: param.bankname5,
    };
    const { zins1, zins2, zins3, zins4, zins5 } = checkedCheckBoxes(
      data,
      banksData
    );
    state.singleReport.chartParam = {
      ...state.singleReport.chartParam,
      chartMinValue: ![zins1, zins2, zins3, zins4, zins5].includes(true),
      chartMaxValue: ![zins1, zins2, zins3, zins4, zins5].includes(true),
      chartAvgValue: ![zins1, zins2, zins3, zins4, zins5].includes(true),
    };
  },
  updateSingleReport(state, payload) {
    // reset to default values
    state.singleReport.chartParam = {
      ...state.singleReport.chartParam,
      zins1: true,
      zins2: true,
      zins3: true,
      zins4: true,
      zins5: true,
    };
    const {
      chartAvgValue,
      hartMaxValue,
      chartMinValue,
      chartZinsValue,
      ...rest
    } = payload.param;
    const banksData = {
      bankname1: rest.bankname1,
      bankname2: rest.bankname2,
      bankname3: rest.bankname3,
      bankname4: rest.bankname4,
      bankname5: rest.bankname5,
    };

    const { zins1, zins2, zins3, zins4, zins5 } = checkedCheckBoxes(
      payload,
      banksData
    );
    if ([zins1, zins2, zins3, zins4, zins5].includes(true)) {
      Object.assign(state.singleReport.chartParam, {
        chartMinValue: false,
        chartMaxValue: false,
        chartAvgValue: false,
      });
    } else {
      Object.assign(state.singleReport.chartParam, {
        chartMinValue: true,
        chartMaxValue: true,
        chartAvgValue: true,
      });
    }
    const params = rest;
    if (params.hasOwnProperty('vermittlerangebote')) {
      if (params.vermittlerangebote === 0) {
        params.vermittlerangebote = { value: 0, selected: false };
      } else {
        params.vermittlerangebote = { value: 1, selected: true };
      }
    }
    state.loading = false;
    state.copySingleReport = {
      ...state.copySingleReport,
      formParam: params,
      data: payload.data,
    };
    state.singleReport = {
      ...state.singleReport,
      formParam: params,
      data: payload.data,
    };
  },
  mapSkeleton(state, payload) {
    const { type, result } = payload;
    state[REPORTS[type]] = result;
  },
  setUploadedReports(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state.uploadedReports[key] = val;
  },
  fetchAllReportsSuccess(state, payload) {
    if (payload.type === 'baugeld') {
      state.allReportsBaugeld = modifyReports(
        state.allReportsBaugeld,
        payload.payload
      );
      state.updatedReportsBaugeld = false;
    }
    if (payload.type === 'festgeld') {
      state.allReportsFestgeld = modifyReports(
        state.allReportsFestgeld,
        payload.payload
      );
      state.updatedReportsFestgeld = false;
    }
    if (payload.type === 'tagesgeld') {
      state.allReportsTagesgeld = modifyReports(
        state.allReportsTagesgeld,
        payload.payload
      );
      state.updatedReportsTagesgeld = false;
    }
  },
  setSingleReport(state, payload) {
    const { data, title, slug, timestamp, config, ...rest } = payload;
    const { param } = rest;
    const { chartMinValue, chartMaxValue, chartAvgValue, ...other } = param;
    const defaultFormParams = setDefaultForm(rest.report_type);
    const result = modifyFormData(defaultFormParams, other);
    let defaultChartParams = defaultState().singleReport.chartParam;
    if (config.chartParams) {
      defaultChartParams = config.chartParams;
    }
    state.singleReport = {
      ...state.singleReport,
      slug,
      timestamp,
      data,
      title,
      formParam: result,
      chartParam: defaultChartParams,
    };
    state.copySingleReport = {
      ...state.copySingleReport,
      slug,
      timestamp,
      data,
      title,
      formParam: result,
      chartParam: defaultChartParams,
    };
    const { zins1, zins2, zins3, zins4, zins5 } = checkedCheckBoxes(
      state.singleReport
    );
    if ([zins1, zins2, zins3, zins4, zins5].includes(true)) {
      Object.assign(defaultChartParams, {
        chartMinValue: false,
        chartMaxValue: false,
        chartAvgValue: false,
      });
    } else {
      Object.assign(defaultChartParams, {
        chartMinValue: true,
        chartMaxValue: true,
        chartAvgValue: true,
      });
    }
    state.singleReport = {
      ...state.singleReport,
      slug,
      timestamp,
      data,
      title,
      formParam: result,
      chartParam: defaultChartParams,
    };
    state.copySingleReport = {
      ...state.copySingleReport,
      slug,
      timestamp,
      data,
      title,
      formParam: result,
      chartParam: defaultChartParams,
    };
    state.loading = false;
  },
  setFieldValue(state, { path, value }) {
    let paths = path.split('.');
    state.singleReport = {
      ...state.singleReport,
      [paths[1]]: { ...state[paths[0]][paths[1]], [paths[2]]: value },
    };
  },
  updateVermittlerangebote(state, payload) {
    state.singleReport = {
      ...state.singleReport,
      formParam: {
        ...state.singleReport.formParam,
        vermittlerangebote: {
          value: payload.value,
          selected: payload.selected,
        },
      },
    };
  },
  updateAllReports(state, payload) {
    state[ALL_REPORTS[payload.reportType]] = state[
      ALL_REPORTS[payload.reportType]
    ].filter((item) => item.slug !== payload.slug);
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
  },
};
