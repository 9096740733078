import { createApp } from 'vue';
import dayjs from 'dayjs';

import VueMatomo from 'vue-matomo';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import vClickOutside from 'click-outside-vue3';
import { Tooltip } from 'chart.js';

import App from './App.vue';
import router from './router';
import { store } from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import multiFilters from '@/plugins/multiFilters';

import apolloProvider from './apollo/apollo-provider';

import { MATOMO_SITE_ID, MATOMO_HOST, API_KEY } from './utils/constants';

// @ts-ignore
import mixins from '@/mixins.js';
import filters from './filters';

(Tooltip.positioners as any).custom = function (elements, position) {
  if (!elements.length) {
    return false;
  }
  let offset = 0;
  //adjust the offset left or right depending on the event position
  if (elements[0].element.x / 2 < position?.chart?.width) {
    offset = 35;
  } else {
    offset = -35;
  }
  return {
    x: elements[0].element.x + offset,
    y: elements[0].element.y - 10,
  };
};

axios.defaults.headers.common['ApiKey'] = API_KEY; //for wettbewerb
const app = createApp(App);
app.mixin(mixins);
app.config.globalProperties.$date = dayjs;
app.config.globalProperties.$filters = filters;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://393688aed075406daf6689d405b0e097@o828031.ingest.sentry.io/5817385',
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
  });
}
app.use(vClickOutside);
app.use(multiFilters);
app.use(apolloProvider);
app.use(vuetify);
app.use(router);
app.use(VueMatomo, {
  host: MATOMO_HOST,
  trackerFileName: 'matomo',
  //trackerScriptUrl: `${process.env.VUE_APP_MATOMO_TRACKER_ENDPOINT}`,
  siteId: `${MATOMO_SITE_ID}`,
  router: router,
  enableLinkTracking: false,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: [],
});

app.use(i18n);
app.use(store);

app.mount('#app');
