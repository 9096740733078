import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function defaultState() {
  return {
    chartDataFestGeld: {
      show: false,
      data: [],
    },
    chartDataHypotheken: {
      show: false,
      data: [],
    },
    chartData: {
      show: false,
      data: [],
    },
  };
}

const state = defaultState();

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
