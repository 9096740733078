<template>
  <v-navigation-drawer
    v-model="valueDrawer"
    :rail="valueMini"
    :width="360"
    permanent
    clipped
  >
    <v-list class="navigation-container px-0 py-0">
      <div v-for="(item, index) in navigationConfig" :key="index">
        <div v-if="!item.isPanel && validationNotPanels(item.permission)">
          <v-list-item
            :to="item.to"
            link
            active-class="panel-active"
            v-if="!item.tooltip"
            class="navigation-item"
          >
            <template v-slot:prepend>
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <v-list-item-title class="list-title default-font">{{
              $t(item.title)
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :active="isActive(item)"
            v-else
            :to="item.to"
            link
            active-class="panel-active"
            class="pr-0"
          >
            <v-tooltip location="right" :color="color">
              <template v-slot:activator="{ props }">
                <div v-bind="props" class="d-flex list-with-tooltip">
                  <div class="v-list-item__prepend">
                    <v-icon>{{ item.icon }}</v-icon>
                  </div>
                  <div class="v-list-item__content">
                    <v-list-item-title class="list-title default-font">
                      <span
                        class="default-font"
                        v-if="item.title === 'sidebar.menu.providerProfiles'"
                        >{{ $t(item.title) }} <sup>BETA</sup></span
                      >
                      <span v-else class="default-font">{{
                        $t(item.title)
                      }}</span>
                    </v-list-item-title>
                  </div>
                </div>
              </template>
              <span class="tooltip-info inter">{{
                $t(item.tooltipText || '')
              }}</span>
            </v-tooltip>
          </v-list-item>
        </div>
        <div class="panel-container" v-if="item.isPanel">
          <v-expansion-panels
            class="menu-panel"
            v-model="panel"
            @update:modelValue="handleChangePanel"
          >
            <v-expansion-panel
              active-class="panel-active"
              class="panel-not-active"
              v-for="panelIndex in [1, 2, 4, 6, 7]"
              :key="panelIndex"
              v-show="panelIndex === index"
            >
              <v-tooltip v-if="validate(item) && item.tooltip">
                <template v-slot:activator="{ props }">
                  <v-expansion-panel-title
                    v-bind="props"
                    :class="item.className"
                  >
                    <v-icon :class="{ rotate: (item as any).rotateIcon}">{{
                      item.icon
                    }}</v-icon>
                    <v-list-item-title class="list-title default-font">{{
                      $t(item.title)
                    }}</v-list-item-title>
                  </v-expansion-panel-title>
                </template>
                <span class="tooltip-info inter">{{
                  $t(item.tooltipText || '')
                }}</span>
              </v-tooltip>

              <v-expansion-panel-title v-if="validate(item) && !item.tooltip">
                <div :class="item.className">
                  <v-icon v-if="item.label === 'Averages'">
                    <img src="/assets/img/compass.svg" alt="Compass" />
                  </v-icon>
                  <v-icon v-else :class="{ rotate: (item as any).rotateIcon}">{{
                    item.icon
                  }}</v-icon>

                  <v-list-item-title class="list-title default-font">{{
                    $t(item.title)
                  }}</v-list-item-title>
                </div>
              </v-expansion-panel-title>
              <div class="panel-content">
                <v-expansion-panel-text
                  v-for="(children, index) in item.items"
                  :key="index"
                >
                  <v-list-item
                    :to="children.to"
                    link
                    active-class="active-link"
                    v-if="permissions.includes(children.permission)"
                  >
                    <v-list-item-title
                      class="list-title default-font"
                      :class="{
                        activeLink: addActiveClass(children),
                      }"
                      >{{ $t(children.title) }}</v-list-item-title
                    >
                  </v-list-item>
                </v-expansion-panel-text>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-list>
    <template v-slot:append>
      <div class="toggle-menu-icon" @click="handleChangeDrawer">
        <v-icon
          class="navigation-mini-icon"
          :color="blackColor"
          :class="{ 'rotate-icon': mini }"
          >mdi-arrow-left-drop-circle</v-icon
        >
        <span class="base-normal-color">{{ t('general.actionBtn.zoom') }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { useI18n } from 'vue-i18n';

import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  NAVIGATION_CONFIG,
} from '@/utils/constants';

export default defineComponent({
  name: 'Navigation',
  props: {
    drawer: Boolean,
    mini: Boolean,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      color: BASE_COLOR,
      blackColor: BASE_BLACK_COLOR,
      panel: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        if (
          this.$router.currentRoute.value.path === '/' ||
          this.$router.currentRoute.value.path === '/chart-generator' ||
          this.$router.currentRoute.value.path === '/banking-profile' ||
          this.$router.currentRoute.value.path === '/mittelwerte/girokonto'
        ) {
          this.panel = null;
        }
        if (this.$router.currentRoute.value.meta.hasOwnProperty('panel')) {
          if (this.mini) {
            this.$emit('change-drawer', {
              drawer: !this.drawer,
              mini: !this.mini,
            });
            this.setAppSideBarOpen(!this.drawer);
          }
          if (!this.panel) {
            this.panel = this.currentPanel();
          }
        }
        if (
          this.error &&
          !Object.keys(this.$router.currentRoute.value.params).length
        ) {
          this.resetError();
        }
        if (
          this.wizardError &&
          !Object.keys(this.$router.currentRoute.value.params).length
        ) {
          this.resetWizardError();
        }
        if (
          !Object.keys(this.$router.currentRoute.value.params).length &&
          !this.$router.currentRoute.value.path.includes('banking-profile')
        ) {
          this.clearSingleReport();
        }
      },
    },
    mini() {
      if (this.mini && this.panel !== null) {
        this.panel = null;
      }
      if (
        !this.mini &&
        this.panel === null &&
        this.$router.currentRoute.value.meta.hasOwnProperty('panel')
      ) {
        this.panel = this.currentPanel();
      }
    },
  },
  methods: {
    ...mapMutations({
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearSingleReport: 'reports/clearSingleReport',
      clearDatenaboData: 'datenabo/clearDatenaboData',
      setAppSideBarOpen: 'app/setAppSideBarOpen',
    }),
    isActive(item) {
      const route = this.$router.currentRoute.value.path
        .split('/')
        .filter((i) => i)[0];
      const to = item.to.split('/').filter((i) => i)[0];
      return route === to;
    },
    addActiveClass(val) {
      const parts = this.$router.currentRoute.value.path
        .split('/')
        .filter((i) => i);
      const partsMenuItemTo = val.to.split('/').filter((i) => i);
      if (this.$router.currentRoute.value.params?.producttype) {
        return (
          `sidebar.menu.${this.$t(
            `sidebar.menu.${this.$router.currentRoute.value.params.producttype}`
          )}` === val.title && partsMenuItemTo[0] === parts[0]
        );
      }
      return partsMenuItemTo[0] === parts[0];
    },
    validationNotPanels(permission) {
      if (Array.isArray(permission)) {
        const search = this.permissions.filter((item) =>
          permission.includes(item)
        );
        return search.length >= 3;
      }
      return this.permissions.includes(permission);
    },
    validate(panel) {
      const allPermissions = panel.items
        .map((item) => item.permission)
        .filter((i) => i);
      if (allPermissions.length) {
        return allPermissions.some((it) => this.permissions.includes(it));
      } else {
        return this.permissions.includes(panel.permission);
      }
    },
    handleChangeDrawer() {
      this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
      this.setAppSideBarOpen(this.mini);
    },
    currentPanel() {
      const permissions = JSON.parse(localStorage.getItem('permissions') || '');
      const arr = this.navigationConfig.map((item) => {
        const allPermissions = item.items
          .map((item) => item?.permission)
          .filter((i) => i);
        if (allPermissions.length) {
          return allPermissions.some((it) => permissions.includes(it)) && item;
        }
        return permissions.includes(item.permission) && item;
      });
      const indexes = {
        1: 0,
        2: 1,
        4: 2,
        6: 3,
        7: 4,
      };
      return indexes[
        arr.findIndex(
          (item) => item?.label === this.$router.currentRoute.value.meta.panel
        )
      ];
    },
    handleChangePanel(e) {
      if (typeof e === 'undefined') {
        this.panel = null;
      } else {
        this.panel = e;
      }
      if (this.mini && this.panel !== null) {
        this.$emit('change-drawer', { drawer: !this.drawer, mini: !this.mini });
        this.setAppSideBarOpen(!this.drawer);
      }
    },
  },
  computed: {
    ...mapGetters({
      error: 'reports/error',
      wizardError: 'wizard/error',
      maxColumns: 'wizard/maxColumns',
      updateExistingReport: 'reports/updateExistingReport',
      permissions: 'auth/getPermissions',
    }),
    navigationConfig() {
      return NAVIGATION_CONFIG;
    },
    valueMini: {
      get: function () {
        return this.mini;
      },
      set() {},
    },
    valueDrawer: {
      get: function () {
        return this.drawer;
      },
      set() {},
    },
  },
});
</script>

<style lang="scss" scoped>
.v-list-item__prepend > .v-icon {
  color: $base-color;
}
.active {
  align-items: center !important;
  flex-direction: row !important;
  padding-left: 20px !important;
  & > img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
  & > div > img:first-child {
    width: 60px;
    height: 50px;
  }
  & > div > img:last-child {
    padding-top: 10px;
    margin-left: 30px;
  }
}
.toggle-menu-icon {
  display: flex;
  padding: 16px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  & > i {
    margin-right: 32px;
  }
  & > span {
    color: $base-color;
  }
}

.panel-not-active {
  margin-top: 0 !important;
  background-color: $background !important;
  & > button {
    padding: 0 16px;
  }
}
.panel-active:focus:before {
  opacity: 0 !important;
}

.panel-active {
  .title-with-tooltip > .list-title {
    color: $text;
  }
  & > .v-list-item__icon > .v-icon {
    color: $text !important;
  }
  & > .v-list-item__content > .list-title {
    color: $text !important;
  }
}
.active-link > .v-list-item__content > .list-title {
  color: $text !important;
}
.list-with-tooltip {
  & > [class~='v-list-item__prepend'] {
    & > [class~='v-icon'] {
      margin-right: 32px !important;
      opacity: 1;
      color: $base-color !important;
    }
  }
  & > .v-list-item__content > .v-list-item-title {
    color: $base-color !important;
  }
}

.v-list-item--active {
  .list-with-tooltip {
    & > [class~='v-list-item__prepend'] {
      & > [class~='v-icon'] {
        color: $text !important;
      }
    }
    & > .v-list-item__content > .v-list-item-title {
      color: $text !important;
    }
  }
}
.activeLink {
  color: $text !important;
}
.v-expansion-panels {
  min-height: 56px;
}

.parent-header {
  display: flex;
  & > .v-icon {
    color: $base-color !important;
    margin-right: 32px;
  }
  & > [class~='list-title'] {
    color: $base-color !important;
  }
}

.rotate {
  transform: rotate(180deg);
}
.menu-panel {
  & > .v-expansion-panel {
    &:before {
      box-shadow: none !important;
    }
    &:after {
      border: none;
    }
    border-radius: 0 !important;
  }
}
.tooltip-info {
  white-space: pre;
}
.navigation-mini-icon {
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.rotate-icon {
  transform: rotate(180deg);
}
</style>
