const primary = {
  base: '#085191',
  secondary: '#1194DB',
  lighten1: '#2EAFED',
  lighten2: '#50BDF0',
  lighten3: '#80D0F5',
  lighten4: '#B2E2F9',
  lighten5: '#E1F4FD',
  darken1: '#13A2EA',
  darken3: '#0E81C7',
  darken4: '#0C71B3',
  orange: '#f48500',
  anchor: '#8c9eff',
  selected: '#00ab97',
};
const error = '#B00020';
const success = '#4CAF50';

export default Object.freeze({
  primary,
  error,
  success,
});
