import { createVuetify } from 'vuetify';

import DayJsAdapter from '@date-io/dayjs';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';

import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';
import { de as deDE, en as enUS } from 'vuetify/locale';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import fmhColors from '@/utils/fmh-colors';

const lightTheme = {
  dark: false,
  colors: {
    primary: fmhColors.primary.base,
    secondary: fmhColors.primary.secondary,
    anchor: fmhColors.primary.anchor,
    selected: fmhColors.primary.selected,
  },
};

const opts = {
  locale: {
    locale: 'de',
    fallback: 'de',
    messages: { en: enUS, de: deDE },
  },
  date: {
    adapter: DayJsAdapter,
    locale: {
      en,
      de,
    },
  },
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
    },
  },
};

// @ts-ignore
export default createVuetify(opts);
