import { CURRENT_YEAR } from '../constants';

const en = {
  general: {
    '10 teuerste anbieter': '10 most expensive providers',
    fmhindexbank: 'FMH index bank',
    regionalebank: 'Regional bank',
    'top-30-anbieter': 'Top 30 providers',
    banks: 'Banks',
    sollzins: 'Borrowing rate',
    effektivzins: 'Effective interest rate',
    sollzinsbindung: 'Fixed-rate period',
    darlehen: 'Loan',
    kaufpreis: 'Purchase price',
    datum: 'Date',
    top: 'Top',
    all: 'All',
    debitInterestRate: 'Fixed interest rate',
    provider: 'Provider',
    totalTable: 'Complete chart',
    anbietername: 'Provider’s name',
    neukundenangebot: 'Offer for new customers',
    zinssatz: 'Interest rate',
    rendite: 'Return',
    Ja: 'Yes',
    ja: 'Yes',
    Nein: 'No',
    nein: 'No',
    month: 'Months',
    investmentPeriod: 'Investment period',
    existingCustomersOffers: 'Offers for current customers',
    onlyNewCustomersOffers: 'Only offers for new customers',
    allOffers: 'All offers',
    customerBase: 'Customer group',
    offersFor: 'Offers for',
    investmentAmount: 'Investment amount',
    minInterestRate: 'Min. interest rate',
    maxInterestRate: 'Max. interest rate',
    date: 'Date',
    Notiz: 'Note',
    title: 'Title',
    note: 'Note',
    from: 'from',
    to: 'to',
    with: 'with',
    possibleOffers: 'possible offers',
    institutes: 'institutes',
    instSelected: 'Selected institutes',
    addSubBank: 'Add sub-bank',
    configureSampleCalculation: 'Configure sample calculation',
    years: 'Years',
    year: 'Years',
    average: 'Mean value',
    showChartWith: 'Show chart with',
    placeholders: {
      'Bitte wählen...': 'Please select ...',
      pleaseSelect: 'Please select',
      bitte_wählen: 'Please select ...',
      purchasePrice: 'Purchase price',
      credit: 'Loan',
      debitInterest: 'Borrowing rate',
      provider: 'Provider',
      effectiveInterestRate: 'Effective interest rate',
    },
    actionBtn: {
      refreshTable: 'Update table',
      download: 'Download',
      forWeb: 'For Web',
      forDoc: 'For Documents',
      forDocSmall: 'For Documents (small)',
      preview: 'Preview',
      sendEmail: 'Send e-mail',
      passwordForgotten: 'Forgot Your Password?',
      logIn: 'Sign in',
      codeCopied: 'Code copied!',
      embedGraphic: 'Embed Code',
      refresh: 'Update',
      newPattern: 'New template',
      selectNone: 'Select none',
      selectAll: 'Select all',
      showDataSeries: 'Show data series',
      hideDataSeries: 'Hide data series',
      logout: 'Sign out',
      overview: 'Overview',
      resetFilters: 'Reset filters',
      reset: 'Reset',
      showEvaluation: 'Show report',
      save: 'Save',
      cancel: 'Cancel',
      updateFilter: 'Update filters',
      zoom: 'Minimise',
      today: 'Today',
      createReport: 'Create report',
      createEvaluation: 'Create report',
      shortTerm: 'Short term',
      longTerm: 'Long term',
      next: 'Next',
      back: 'Back',
      apply: 'Apply',
      select: 'Select',
      newProduct: 'New product',
      close: 'Close',
      Hinzufügen: 'Add',
      Speichern: 'Save',
      delete: 'Delete',
      edit: 'Edit',
      copy: 'Copy',
    },
    noData: 'No data available',
    subHeadlines: {
      buildingMoney: 'Mortgage',
      callMoney: 'Call money',
      fixedDeposit: 'Fixed deposit',
      girokonto: 'Checking Account',
    },
    warning: {
      titleRequired: 'A title is required',
      alert: 'Caution',
      notice: 'Information',
    },
    dropDownValues: {
      '5 Jahre': '5 years',
      '10 Jahre': '10 years',
      '15 Jahre': '15 years',
      '20 Jahre': '20 years',
      '25 Jahre': '25 years',
      '30 Jahre': '30 years',
      'Alle Angebote': 'All offers',
      Privatperson: 'Private individual',
      Geschäftskunde: 'Business client',
    },
    xls: {
      data_from: 'Data from',
      trends_of: 'and trends of the last two weeks',
    },
  },
  German: 'German',
  English: 'English',
  header: {
    bankingPortal: 'The whole market at your fingertips.',
  },
  subHeader: {
    lastUpdated: 'Last update',
  },
  footer: {
    financialConsulting: 'FMH-Finanzberatung e.K.',
    agb: 'Terms and Conditions',
    anb: 'Terms of Use',
    financialConsultingAddress: 'Zeil 13, D-60313 Frankfurt am Main',
    contact: 'Contact',
    imprint: 'Site Notice',
    privacy: 'Privacy Policy',
    phone: 'Phone',
    fax: 'Fax',
  },
  sidebar: {
    menu: {
      currentAccount: 'Current account',
      averages: 'Averages',
      dashboard: 'Dashboard',
      interestCharts: 'Chart Generator',
      providerProfiles: 'Provider Profiles',
      liveData: 'Live Data',
      buildingMoney: 'Mortgage',
      baugeld: 'buildingMoney',
      'wettbewerb-baugeld': 'buildingMoney',
      tagesgeld: 'callMoney',
      'wettbewerb-tagesgeld': 'callMoney',
      callMoney: 'Call money',
      festgeld: 'fixedDeposit',
      'wettbewerb-festgeld': 'fixedDeposit',
      fixedDeposit: 'Fixed deposit',
      contest: 'Historical Data',
      nibc: 'NIBC',
      kfw: 'KfW',
      benchmark: 'Benchmark',
    },
    tooltips: {
      chartGenerator:
        'Chart Generator: Create and export your own interest rate and market trend\nvisualisations.',
      bankingProfile:
        'Provider Profiles: Retrieve and compare the master data and balance sheet ratios\nfor financial market players – Europe-wide.',
      liveData:
        'Live Data: Your market surveillance. Retrieve, track and compare interest rates and conditions for\nvarious providers.',
      contest:
        'Historical Data: Your market overview. Visualised market and product trends\nfor loans and investment products.',
    },
  },
  common: {
    tags: {
      'deutsche Anbieter': 'german providers',
      'Deutsche Anbieter': 'German Providers',
      Vermittler: 'intermediaries',
      'Erweiterte Einlagensicherung': 'Extended Deposit Guarantee',
      'flexible Angebote': 'flexible offers',
      'Top-Wettbewerber': 'Top Competitors',
      Neukunden: 'New Customers',
      Freshmoney: 'Fresh money',
      'Regionale Anbieter': 'Top Competitors',
      MaRisk: 'Minimum Requirements for Risk Management',
      'Forward-Darlehen': 'Forward Loan',
      'Förderdarlehen (KfW)': 'Promotional Loan (KfW)',
    },
    dataIterator: {
      noText: 'Create your first report',
      groupHeadline: {
        latest: 'Your latest reports:',
        autoUpdate: 'Your current market tracks, updated daily:',
        other: 'Your saved market reports:',
      },
      cardActions: {
        open: 'Open',
      },
      simpleTable: {
        template: 'Template',
        products: 'Products',
        provider: 'Provider',
        runtimes: 'Terms',
        offers: 'Offers',
        allOffers: 'All offers',
        existingCustomersOffers: 'Offers for current customers',
        onlyNewCustomersOffers: 'Only offers for new customers',
        selectionCriteria: 'Selection criteria',
        businessClient: 'Business client',
        privatperson: 'Private individual',
      },
      footer: {
        page: 'Page',
        from: 'from',
      },
    },
    datePicker: {
      locale: 'en-US',
      errors: {
        format: 'Please enter the date in MM/YYYY format',
        monthFormat: 'Incorrect month format',
      },
    },
    dateSelection: {
      formatDate: 'DD.MM.YYYY',
      dataFrom: 'Interest data from',
      compareWith: 'Compare with',
      orInterestDataFrom: 'Or interest data from',
      hint: 'Click on the adjacent icon to display interest rate changes from a specific period.',
    },
    noteTagsHint: {
      note: 'Note',
      yourNote: 'Your note',
      tags: 'Tags',
      hint: 'Information',
      info: {
        note: 'View, change or create notes for this overview here.',
        tags: 'Create and manage keywords for this overview here.',
      },
      hintDescription:
        'Data is updated at 3 pm for models with daily updating.',
      createNote: 'Create note',
      createTags: 'Create tags',
    },
    dotsMenu: {
      migrate: 'Migrate',
      downloadPDF: 'Download PDF',
      downloadXLS: 'Download XLS',
      downloadCSV: 'Download CSV',
      duplicate: 'Duplicate',
      compare: 'Export all auxiliary conditions as an XLS',
      edit: 'Edit',
      delete: 'Delete',
    },
    addNewReportCard: {
      text: 'Create new report',
    },
    modalConfirm: {
      titleDelete: 'Are you sure you want to continue?',
      reportName: 'Give your report a name',
      title: 'Caution!',
      confirm: 'Yes',
      reject: 'No',
      save: 'Save',
      cancel: 'Cancel',
      confirmDeleteReport: 'Yes, delete',
    },
    subHeader: {
      text: 'Last update',
    },
    generalTable: {
      moreThanOneCountry: 'a. G +',
      noOffer: 'No new customer offer',
      changesCompared: 'Changes compared to',
      comparison: 'Comparison',
      unlimited: 'unlimited',
      noText: 'No data available',
      mediator: 'Intermediaries',
      hintCompare: 'Changes compared to',
      hintTrend: 'Two-week trend',
      interestCreditCallMoney: {
        monthEnd: 'End of month',
        quarterEnd: 'End of quarter',
        yearEnd: 'End of year',
        daily: 'Daily',
        halfYearEnd: 'End of half-year',
        startOfMonth: 'Start of month',
      },
      interestCreditFixedDeposit: {
        monthly: 'Monthly',
        quarterly: 'Quarterly',
        semiannual: 'Half-yearly',
        yearly: 'Annually',
        termEnd: 'End of term',
      },
      interestCalculation: {
        germanMethod: '30/360',
        euroInterestMethod: 'ACT/360',
        englishMethod: 'ACT/365',
        effectiveInterestMethod: 'ACT/ACT',
      },
      headers: {
        zielgruppe: 'Target group',
        customerBase: 'Customer group',
        produkt_maximalanlageProdukt: 'Max. investment',
        produkt_neukundenangebot: 'Interest rate change',
        produkt_abBetrag: 'Interest applies \nfrom €',
        produkt_anschlussZinssatz: 'Subsequent interest',
        produkt_zinssatz_difference: 'Difference',
        '1M_zinssatz_difference': 'Difference',
        '2M_zinssatz_difference': 'Difference',
        '3M_zinssatz_difference': 'Difference',
        '6M_zinssatz_difference': 'Difference',
        '9M_zinssatz_difference': 'Difference',
        '12M_zinssatz_difference': 'Difference',
        '18M_zinssatz_difference': 'Difference',
        '24M_zinssatz_difference': 'Difference',
        '36M_zinssatz_difference': 'Difference',
        '48M_zinssatz_difference': 'Difference',
        '60M_zinssatz_difference': 'Difference',
        '72M_zinssatz_difference': 'Difference',
        '84M_zinssatz_difference': 'Difference',
        '96M_zinssatz_difference': 'Difference',
        '108M_zinssatz_difference': 'Difference',
        '120M_zinssatz_difference': 'Difference',
        '1M_zinssatzVeraenderung': 'Difference',
        '2M_zinssatzVeraenderung': 'Difference',
        '3M_zinssatzVeraenderung': 'Difference',
        '6M_zinssatzVeraenderung': 'Difference',
        '9M_zinssatzVeraenderung': 'Difference',
        '12M_zinssatzVeraenderung': 'Difference',
        '18M_zinssatzVeraenderung': 'Difference',
        '24M_zinssatzVeraenderung': 'Difference',
        '36M_zinssatzVeraenderung': 'Difference',
        '48M_zinssatzVeraenderung': 'Difference',
        '60M_zinssatzVeraenderung': 'Difference',
        '72M_zinssatzVeraenderung': 'Difference',
        '84M_zinssatzVeraenderung': 'Difference',
        '96M_zinssatzVeraenderung': 'Difference',
        '108M_zinssatzVeraenderung': 'Difference',
        '120M_zinssatzVeraenderung': 'Difference',
        produkt_zinssatzVeraenderung: 'Difference',
        produkt_aktionszinssatzVeraenderung: 'Difference',
        anbieter: 'Provider',
        produkt: 'Product',
        '1M_zinssatz': '1 month',
        '3M_zinssatz': '3 months',
        '6M_zinssatz': '6 months',
        '9M_zinssatz': '9 months',
        '12M_zinssatz': '12 months',
        '18M_zinssatz': '18 months',
        '24M_zinssatz': '24 months',
        '36M_zinssatz': '36 months',
        '48M_zinssatz': '48 months',
        '60M_zinssatz': '60 months',
        '72M_zinssatz': '72 months',
        '84M_zinssatz': '84 months',
        '96M_zinssatz': '96 months',
        '108M_zinssatz': '108 months',
        '120M_zinssatz': '120 months',
        produkt_datumZinsaenderung: 'Interest rate change',
        produkt_mindestanlage: 'Min. investment',
        produkt_maximalanlage: 'Max. investment',
        produkt_zinsgutschrift: 'Credit note',
        produkt_zinsberechnung: 'Calculation',
        einlagensicherung: 'Deposit guarantee',
        produkt_zinssatz: 'Interest rate',
        produkt_aktionszinssatz: 'Promotional interest rate',
        aktionszins: 'Promotional interest rate',
        produkt_zinsgarantie: 'Interest rate guarantee',
        '2M_zinssatz': '2 months',
      },
      hints: {
        extended_deposit_insurance: 'Extended deposit insurance',
      },
    },
  },
  login: {
    titles: {
      resetPassword: 'Reset password',
    },
    errors: {
      dataNotCorrect: 'User data not correct. Please try again.',
      apiError: 'An error occurred during the API request.',
    },
    placeholders: {
      enterYourEmail:
        'Please enter your account email address and we will send you a password reset link',
      password: 'Password',
      email: 'Email',
    },
    rules: {
      emptyEmail: 'E-mail must be filled',
      emptyPassword: 'Password must be filled in',
      wrongEmailFormat: 'E-mail has wrong format',
    },
    messages: {
      checkYourEmail: 'Please check your email',
    },
  },
  dashboard: {
    topAnbieter: {
      tagesgeldTop5: 'Call Money – top 5',
      festgeldTop5: 'Fixed deposit – top 5',
      rang: 'Ranking',
      zins: 'Interest rate',
      competitor: 'Competitors',
      '2y': '2-year',
      '5y': '5-year',
      '10y': '10-year',
      '3m': '3-month',
      '6m': '6-month',
      '12m': '12-month',
      noData: 'No data available',
    },
    headerTitle: 'The whole market at your fingertips.',
    firstWelcome: 'Welcome to your FMH X Radar dashboard',
    welcome: 'Hello there!',
    newHere:
      'Draw on our innovative SaaS solution to improve the efficiency and ease of your market monitoring. Acquire a more detailed impression of the latest trends and developments to remain consistently up to date and respond quickly to changes.',
    toVideoGuide: 'To the video guide',
    everythingClear: 'Understood? With which report do you want to begin?',
    marketSurveillance: 'Market surveillance',
    createEvaluation: 'Create a report about \n competitor conditions',
    selectLabel: 'Select product ...',
    competitiveComparison: 'Competitive comparison',
    visualizeDevelopment:
      'Visualise the trends for \n loan and investment products',
    contest: 'Competitive',
    comparison: 'comparison',
    interest: 'Interest rate',
    interestRateCharts: 'Chart Generator',
    compareFinancial: 'Compare financial products \n and indexes',
    newChart: 'New chart',
    yourLatestEvaluations: 'Your latest reports',
    whatToDoNext: 'What do you want to do next?',
    createInterestRateChart: 'Generate a chart',
    trackConstructionMoney: 'Track mortgage',
    createCallMoneyComparison: 'Create a call money report',
  },
  benchmark: {
    filters: {
      explanation: '*Girocard (German debit card system) or debit card',
      legend: 'Create benchmark',
      nachBankTypeLabel: 'Filter by bank type or federal state',
      zeitPunkteLabel: 'Compare time points',
      bankTypeLabel: 'Filter by financial institution type',
      kontoLabel: 'Pricing of account and card',
      transaktionenLabel: 'Transaction fees',
      actionButton: 'Load Data',
      labelInOptions: {
        sparkassen: 'Savings Banks (Sparkassen)',
        genossenschaftsbanken: 'Cooperative banks (Genossenschaftsbanken)',
        geschaftsbanken: 'Commercial banks',
        direktbanken: 'Direct banks',
        bundesweiteBanken: 'Nationwide banks',
        regionaleBanken: 'Regional banks',
        vermittlerbanken: '',
        monatlicherGrundpreis: 'Monthly base fee',
        dispozins: 'Overdraft interest rate',
        jahresgebuhrFurBankKarte: 'Annual fee for debit card',
        jahresgebuhrKreditkarte: 'Annual fee for credit card',
        gutschrift: 'Credit',
        lastschrift: 'Direct debit',
        beleghafteUberweisung: 'Paper-based transfer',
        belegloseUberweisung: 'Paperless transfer',
        baden: 'Baden-Württemberg',
        bayern: 'Bavaria',
        berlin: 'Berlin',
        brandenburg: 'Brandenburg',
        bremen: 'Bremen',
        hamburg: 'Hamburg',
        hessen: 'Hesse',
        mecklenburg: 'Mecklenburg-Vorpommern',
        niedersachsen: 'Lower Saxony',
        nordrhein: 'North Rhine-Westphalia',
        rheinland: 'Rhineland-Palatinate',
        saarland: 'Saarland',
        sachsen: 'Saxony',
        sachsenAnhalt: 'Saxony-Anhalt',
        schleswigHolstein: 'Schleswig-Holstein',
        thuringen: 'Thuringia',
      },
      errors: {
        noDatesSelected: 'Please choose a date.',
        twoSameDates: 'Please select two different dates.',
        nothingSelected: 'Please select at least one bank type or state.',
      },
    },
    title: 'Benchmark',
    explanations:
      'See the average values of the most important costs and fees associated with current accounts on the market at a ' +
      'glance. Filter by region or bank type to obtain exactly the information that is relevant to your analysis.',
    table: {
      title: 'Result',
      totalResult: 'Total result',
      headers: {
        diff: 'Difference',
        designationBankCategory: 'State',
        numberBanks: 'Institutions',
        numberProducts: 'Current accounts',
        basePrice: 'Average Base price',
        discountInterest: 'Average Overdraft interest rate',
        jahresgebuhrFurBankKarte: 'Debit card',
        jahresgebuhrKreditkarte: 'Credit card',
        gutschrift: 'Credit',
        lastschrift: 'Direct debit',
        beleghafteUberweisung: 'Paper-based \ntransfer',
        belegloseUberweisung: 'Paperless \ntransfer',
      },
    },
  },
  liveData: {
    generalTable: {
      noOfferTagesgeld: 'No call money offer',
      noOfferFestgeld: 'No fixed deposit offer',
    },
    title: 'Live Data',
    explanations:
      'Here you can see your most recent reports in\nchronological order. You can\ncreate new reports or modify, export and duplicate existing\nones. Research daily interest rates and\nconditions, save the most important snapshots or configure\nregular tracking of products by your biggest\ncompetitors.',
  },
  singleReport: {
    title: 'Personalize evaluation',
    dateSelection: {
      label:
        'You can also compare conditions at two different times. \n Start by selecting a date that is some time in the past.',
      button: 'Load Live Data',
      compareButton: 'Compare Time Periods',
    },
  },
  chartGenerator: {
    showYAxis: 'Vertical lines',
    minMax: 'Include min. and max. values',
    timePeriod: 'Select period',
    selectProduct: 'Select product',
    selectType: 'Select type',
    explanations:
      'Use the Chart Generator to visualise interest rate trends and market developments according to your needs and download the chart as an image file or integrate it with iFrame.',
    criteria: 'Criteria',
    title: 'Chart Generator',
    subHeadline: 'Create and export charts',
    explanation:
      'Create your own interest rate and market trends here. Please note: The export function will be available soon.',
    contact: 'Feel free to contact us',
    wantExport:
      'if you want to export a chart or the associated data or move it to your own BI solution and web applications via API.',
    tab1Text: 'Basic Charts',
    tab2Text: 'Advanced Charts',
    step: 'Step',
    series: 'Metric',
    subtitle: 'Select up to 3 data series',
    setPeriod: 'Define period',
    specialFeatures: 'Special factors',
    rules: {
      dataEntryRequired: 'Input required!',
    },
    createGraphic: 'Generate chart',
    embedGraphic: 'Embed Code',
    chartTypes: {
      lineChart: 'Line graph',
      areaChart: 'Area graph',
    },
    differential: 'Difference',
    annotations: 'Annotations',
    fmhLogo: 'Include FMH X logo',
    logo: 'Logo',
    sourcePosition: {
      label: 'Source position',
      options: {
        topLeft: 'Top left',
        topRight: 'Top right',
        bottomLeft: 'Bottom left',
        bottomRight: 'Bottom right',
      },
    },
    hints: {
      sourcePosition:
        'Adjust the placement of the source information and time period within the chart.',
      showMinMax: 'You can hide the minimum and maximum values.',
      split:
        'You can interrupt scaling \n to display more detailed interest rate curves in finer index gradations if two curves are very far apart – such as for call money and a current account overdraft facility.',
      differentialValue:
        'Visualise the difference between two curves: The value in the lower data series is subtracted from the upper one.',
      annotation:
        'Global political decisions, economic events, major natural disasters:\n See which events influenced the interest rates.',
      showLogo: 'You can also hide the logo in the chart.',
    },
    interestOptions: {
      tagesgeld: 'Call Money',
      festgeld: 'Fixed deposit',
      festgeldSparbrief: 'Fixed deposit (long term)',
      sparbuch: 'Savings account',
      hypothekenzinsen: 'Mortgage interest rates',
      ratenkredit: 'Instalment loan',
      giro: 'Current account',
      euribor: 'Euribor',
      eonia: 'EONIA/ESTER',
      ezb_emp: 'Key ECB interest rate',
      inflation: 'Inflation',
      umlaufrendite: 'Current yield',
      ba_10: '10-year German government bond',
      pfandbriefe: 'Covered bonds (according to the Bundesbank)',
      basiszins_bgb: 'Basic rate of interest',
    },
    subInterestOptions: {
      ezb_emp: {
        ezb_einlagenzins: 'Deposit facility (DFR)',
        ezb: 'Main refinancing operations (MRO)',
      },
      pfandbriefe: {
        pb_1: '1 year',
        pb_5: '5 years',
        pb_10: '10 years',
      },
      tagesgeld: {
        tg_basis: 'from 1 €',
        tg_5000: '€5,000',
        tg_50000: '€50,000',
        tg_bb: 'according to the Bundesbank',
      },
      festgeld: {
        sb_2: '2 years',
        sb_3: '3 years',
        sb_4: '4 years',
        sb_5: '5 years',
        sb_7: '7 years',
        sb_10: '10 years',
        fg_5000_3: '3 months',
        fg_5000_6: '6 months',
        fg_5000_12: '12 months',
        fg_50000_3: '3 months',
        fg_50000_6: '6 months',
        fg_50000_12: '12 months',
      },
      sparbuch: {
        spareckzins: 'Base/basic savings rate',
        spareinlage_50000: '€50,000',
      },
      hypothekenzinsen: {
        bg_5: '5 years',
        bg_10: '10 years',
        bg_15: '15 years',
        bg_20: '20 years',
        kfw_124: 'KfW 124',
      },
      ratenkredit: {
        rk_36: '36 months',
        rk_48: '48 months',
        rk_60: '60 months',
        rk_72: '72 months',
      },
      giro: {
        dispo: 'Overdraft facility',
        dispo_plus: 'Additional overdrafts',
      },
      euribor: {
        euribor_1: '1 month',
        euribor_3: '3 months',
        euribor_6: '6 months',
      },
    },
    specialChartOptions: {
      rbGrafikAuswahl1: 'Mortgage interest rates from 1980',
      rbGrafikAuswahl2: 'Building society interest rates from 1993',
      rbGrafikAuswahl3: 'Forward premiums from 2010',
      goldpreis: 'Gold price according to the Bundesbank',
      rbGrafikAuswahl5: 'House price trend from 2004',
    },
    specialChartSubOptions: {
      rbGrafikAuswahl1: {
        effektivzins_5: '5-year fixed interest rate',
        effektivzins_10: '10-year fixed interest rate',
        effektivzins_15: '15-year fixed interest rate',
      },
      rbGrafikAuswahl2: {
        bs_guthabenszins: 'Building society savings account interest rate',
        bs_tagesgeld_index: 'Interest on call money',
        bs_darlehenszins: 'Building society interest rate on loans',
        bs_baugeld_10: '10-year mortgage interest rate',
      },
      rbGrafikAuswahl3: {
        fd_aufschlag_12: 'Premium for 12 months',
        fd_aufschlag_24: 'Premium for 24 months',
        fd_aufschlag_36: 'Premium for 36 months',
        fd_effektivzins_12:
          '10-year eff. fixed interest rate incl. \nforward premium for 12 mon.',
        fd_effektivzins_24:
          '10-year eff. fixed interest rate incl. \nforward premium for 24 mon.',
        fd_effektivzins_36:
          '10-year eff. fixed interest rate incl. \nforward premium for 36 mon.',
      },
      rbGrafikAuswahl5: {
        hp_darlehenhoehe: 'Construction loan interest rate and loan amount',
        hp_hypothekenzins_index:
          '10-year construction loan fixed interest rate, quarterly mean as a %',
        hp_einkommen: 'Net income and loan amount',
        hp_einkommen_hp_darlehenhoehe:
          '10-year construction loan fixed interest rate, quarterly mean as a %',
        hp_preis_neubau_etw: 'Loan amount and property prices',
        hp_preis_neubau_etw_hp_darlehenhoehe: 'Possible loan amount in euros',
        hp_preis_neubau_etw_hp_preis_neubau_haus:
          'Mean prices of a new-build home in euros',
        hp_preis_neubau_haus: 'House price and net income',
        hp_preis_neubau_haus_hp_einkommen: 'Net monthly income for 2 persons',
      },
    },
    average: 'Mean value',
    difference: 'Difference',
    and: 'and',
    error: 'Something went wrong with the API request',
    modal: {
      title: 'Copy the code and insert into the source code of your website',
      codeCopied: 'Code copied!',
    },
    colors: 'Colours',
    color: 'Colour',
    specialChartHousePriceDescription: {
      hp_hypothekenzins_index_hp_darlehenhoehe:
        'The interest rates used (80% financing) represent the mean interest rates per quarter (fixed interest rate for 10 years) from the FMH database. The calculated loan amount is based on a financing period of 30 years with a constant interest rate.',
      hp_darlehenhoehe_hp_einkommen:
        'The net income used is based on figures released by the Federal Statistical Office in Feb. 2023. The interest rates used (80% financing) represent the mean interest rates per quarter (fixed interest rate for 10 years) from the FMH database. The calculated loan amount is based on a financing period of 30 years with a constant interest rate.',
      hp_darlehenhoehe_hp_preis_neubau_haus_hp_preis_neubau_etw: `The calculated loan amount is based on a financing period of 30 years with a constant interest rate. The underlying net income is based on figures released by the Federal Statistical Office in Feb. ${CURRENT_YEAR} for a double-income household. The interest rates used (80% financing) represent the mean interest rates per quarter (fixed interest rate for 10 years) from the FMH database. The house and apartment prices (140 and 100 sqm) are based on figures released by empirica in December ${
        CURRENT_YEAR - 1
      } and are quarterly mean values for new builds throughout Germany.`,
      hp_einkommen_hp_preis_neubau_haus:
        'The underlying net income is based on figures released by the Federal Statistical Office in Feb. 2023 for a double-income household. The house prices for 140 sqm of living space are based on figures released by empirica in December 2022 and are quarterly mean values for new builds throughout Germany.',
    },
    cartLabels: {
      hp_hypothekenzins_index:
        '10-year construction loan fixed interest rate, quarterly mean as a %',
      hp_darlehenhoehe: 'Possible loan amount in euros',
      hp_einkommen: 'Net monthly income for 2 persons in euros',
      hp_preis_neubau_haus: 'Mean prices of a new-build home in euros',
      hp_preis_neubau_etw: 'Average new-build apartment prices in euros',
    },
  },
  bankingProfile: {
    more: 'more',
    title: 'New provider – create report',
    headline: 'Provider Profiles',
    explanationText: 'Please enter the title and note for the report',
    selectColumns: 'Select column',
    closeColumnSelect: 'Close column selection',
    update: 'Update',
    selectAll: 'All',
    timePeriod: 'Select period',
    region: 'Select region',
    keyAreas: 'Focal points',
    activity: 'Activity',
    dataFilter: 'Filter data',
    headerTitle: 'Provider Profiles',
    subHeadline: 'Your latest overviews',
    subHeadlineNewReportPage: 'Create a new provider overview',
    explanation:
      'Here you can see your most recent overviews in chronological order. \nYou can create new reports and adapt, export and duplicate existing ones.',
    world: 'World',
    regionOptions: {
      dach: 'DACH',
      europa: 'Europe',
      eu: 'EU',
      benelux: 'Benelux',
      brics: 'BRICS',
      afrika: 'Africa',
      asien: 'Asia',
      'australien und ozeanien': 'Australia and Oceania',
      amerikas: 'Americas',
    },
    countries: {
      Schweiz: 'Switzerland',
      Deutschland: 'Germany',
      Frankreich: 'France',
      Italien: 'Italy',
      Luxemburg: 'Luxembourg',
      Niederlande: 'Netherlands',
      Dänemark: 'Denmark',
      Irland: 'Ireland',
      Griechenland: 'Greece',
      Portugal: 'Portugal',
      Spanien: 'Spain',
      Finnland: 'Finland',
      Österreich: 'Austria',
      Schweden: 'Sweden',
      Estland: 'Estonia',
      Lettland: 'Latvia',
      Litauen: 'Lithuania',
      Malta: 'Malta',
      Polen: 'Poland',
      Slowakei: 'Slovakia',
      Slowenien: 'Slovenia',
      Tschechien: 'Czech Republic',
      Ungarn: 'Hungary',
      Zypern: 'Cyprus',
      Bulgarien: 'Bulgaria',
      Rumänien: 'Romania',
      Kroatien: 'Croatia',
      Albanien: 'Albania',
      Andorra: 'Andorra',
      Belarus: 'Belarus',
      Belgien: 'Belgium',
      'Bosnien und Herzegowina': 'Bosnia and Herzegovina',
      Island: 'Iceland',
      Kasachstan: 'Kazakhstan',
      Kosovo: 'Kosovo',
      Liechtenstein: 'Liechtenstein',
      Moldau: 'Moldova',
      Monaco: 'Monaco',
      Montenegro: 'Montenegro',
      Nordmazedonien: 'North Macedonia',
      Norwegen: 'Norway',
      Russland: 'Russia',
      'San Marino': 'San Marino',
      Serbien: 'Serbia',
      Türkei: 'Turkey',
      Ukraine: 'Ukraine',
      Vatikanstadt: 'Vatican City',
      'Vereinigtes Königreich': 'United Kingdom',
      Indien: 'India',
      China: 'China',
      Südafrika: 'South Africa',
      Ägypten: 'Egypt',
      Algerien: 'Algeria',
      Angola: 'Angola',
      Äquatorialguinea: 'Equatorial Guinea',
      Äthiopien: 'Ethiopia',
      Benin: 'Benin',
      Botswana: 'Botswana',
      'Burkina Faso': 'Burkina Faso',
      Burundi: 'Burundi',
      Dschibuti: 'Djibouti',
      Elfenbeinküste: 'Ivory Coast',
      Eritrea: 'Eritrea',
      Eswatini: 'Eswatini',
      Gabun: 'Gabon',
      Gambia: 'Gambia',
      Ghana: 'Ghana',
      Guinea: 'Guinea',
      'Guinea-Bissau': 'Guinea-Bissau',
      Kamerun: 'Cameroon',
      'Kap Verde': 'Cape Verde',
      Kenia: 'Kenya',
      Komoren: 'Comoros',
      'Kongo, Demokratische Republik': 'Congo, Democratic Republic of',
      'Kongo, Republik': 'Congo, Republic of',
      Lesotho: 'Lesotho',
      Liberia: 'Liberia',
      Libyen: 'Libya',
      Madagaskar: 'Madagascar',
      Malawi: 'Malawi',
      Mali: 'Mali',
      Marokko: 'Morocco',
      Mauretanien: 'Mauritania',
      Mauritius: 'Mauritius',
      Mosambik: 'Mozambique',
      Namibia: 'Namibia',
      Niger: 'Niger',
      Nigeria: 'Nigeria',
      Ruanda: 'Rwanda',
      Sambia: 'Zambia',
      'São Tomé und Príncipe': 'São Tomé and Príncipe',
      Senegal: 'Senegal',
      Seychellen: 'Seychelles',
      'Sierra Leone': 'Sierra Leone',
      Simbabwe: 'Zimbabwe',
      Somalia: 'Somalia',
      Sudan: 'Sudan',
      Südsudan: 'South Sudan',
      Tansania: 'Tanzania',
      Togo: 'Togo',
      Tschad: 'Chad',
      Tunesien: 'Tunisia',
      Uganda: 'Uganda',
      'Zentralafrikanische Republik': 'Central African Republic',
      Afghanistan: 'Afghanistan',
      Armenien: 'Armenia',
      Aserbaidschan: 'Azerbaijan',
      Bahrain: 'Bahrain',
      Bangladesch: 'Bangladesh',
      Bhutan: 'Bhutan',
      Brunei: 'Brunei',
      'Volksrepublik China': 'People’s Republic of China',
      Georgien: 'Georgia',
      Indonesien: 'Indonesia',
      Irak: 'Iraq',
      Iran: 'Iran',
      Israel: 'Israel',
      Japan: 'Japan',
      Jemen: 'Yemen',
      Jordanien: 'Jordan',
      Kambodscha: 'Cambodia',
      Katar: 'Qatar',
      Kirgisistan: 'Kyrgyzstan',
      Nordkorea: 'North Korea',
      Südkorea: 'South Korea',
      Kuwait: 'Kuwait',
      Laos: 'Laos',
      Libanon: 'Lebanon',
      Malaysia: 'Malaysia',
      Malediven: 'Maldives',
      Mongolei: 'Mongolia',
      Myanmar: 'Myanmar',
      Nepal: 'Nepal',
      Oman: 'Oman',
      Pakistan: 'Pakistan',
      Philippinen: 'Philippines',
      'Saudi-Arabien': 'Saudi Arabia',
      Singapur: 'Singapore',
      'Sri Lanka': 'Sri Lanka',
      Syrien: 'Syria',
      Tadschikistan: 'Tajikistan',
      Thailand: 'Thailand',
      Turkmenistan: 'Turkmenistan',
      Usbekistan: 'Uzbekistan',
      'Vereinigte Arabische Emirate': 'United Arab Emirates',
      Vietnam: 'Vietnam',
      Australien: 'Australia',
      Cookinseln: 'Cook Islands',
      Fidschi: 'Fiji',
      Kiribati: 'Kiribati',
      Marshallinseln: 'Marshall Islands',
      Mikronesien: 'Micronesia',
      Nauru: 'Nauru',
      Neuseeland: 'New Zealand',
      Palau: 'Palau',
      Salomonen: 'Solomon Islands',
      Samoa: 'Samoa',
      Tonga: 'Tonga',
      Tuvalu: 'Tuvalu',
      Vanuatu: 'Vanuatu',
      'Amerikanisch-Samoa': 'American Samoa',
      Bougainville: 'Bougainville',
      'Französisch-Polynesien': 'French Polynesia',
      Guam: 'Guam',
      Neukaledonien: 'New Caledonia',
      Niue: 'Niue',
      'Nördliche Marianen': 'Northern Mariana Islands',
      Norfolkinsel: 'Norfolk Island',
      Pitcairninseln: 'Pitcairn Islands',
      Tokelau: 'Tokelau',
      'Wallis und Futuna': 'Wallis and Futuna',
      'United States Minor Outlying Islands':
        'United States Minor Outlying Islands',
      'Antigua und Barbuda': 'Antigua and Barbuda',
      Argentinien: 'Argentina',
      Bahamas: 'Bahamas',
      Barbados: 'Barbados',
      Belize: 'Belize',
      Bolivien: 'Bolivia',
      Brasilien: 'Brazil',
      Chile: 'Chile',
      'Costa Rica': 'Costa Rica',
      Dominica: 'Dominica',
      'Dominikanische Republik': 'Dominican Republic',
      'El Salvador': 'El Salvador',
      Ecuador: 'Ecuador',
      Grenada: 'Grenada',
      Guatemala: 'Guatemala',
      Guyana: 'Guyana',
      Haiti: 'Haiti',
      Honduras: 'Honduras',
      Jamaika: 'Jamaica',
      Kanada: 'Canada',
      Kolumbien: 'Columbia',
      Kuba: 'Cuba',
      Mexiko: 'Mexico',
      Nicaragua: 'Nicaragua',
      Panama: 'Panama',
      Paraguay: 'Paraguay',
      Peru: 'Peru',
      'St. Kitts und Nevis': 'St Kitts and Nevis',
      'St. Lucia': 'St Lucia',
      'St. Vincent und die Grenadinen': 'Saint Vincent and the Grenadines',
      Suriname: 'Suriname',
      'Trinidad und Tobago': 'Trinidad and Tobago',
      Uruguay: 'Uruguay',
      Venezuela: 'Venezuela',
      USA: 'USA',
    },
    filter: {
      fields: 'Selection',
      explanationText: 'Please enter the title and note for the report',
      update: 'Update',
      filter: 'filters',
      dataFilter: 'Filter data',
      selectAll: 'All',
      timePeriod: 'Period',
      region: 'Region',
      activity: 'Activity',
      selectColumns: 'Select column',
      closeColumnSelect: 'Close column selection',
      listedOnStockExchange: 'Listed on the stock exchange',
      timePeriodTooltip:
        'Select the time period for which you want to display financial ratios. \nYou can also choose multiple years. \n' +
        'Then click "Update" to view the data.',
      regionTooltip:
        'You can select entire regions with a single click, select individual countries via “Search country” – or combine both options.\nYou can find your preferred countries even faster by typing the name in the input field.',
    },
    description: 'Brief profile',
    table: {
      loadingText: 'Loading items ...',
      saveXLS: 'XLS download',
      explanationText: 'Load the view as an Excel file',
      selectedAdditionalHeadersLabel: 'Show columns',
      filter: {
        comparison: 'Comparison',
        value: 'Value',
        select: 'Select',
        booleanText: 'Yes/no',
        fields: 'Selection',
        regionLabel: 'Country search',
        larger: 'Greater than',
        smaller: 'Less than',
        equal: 'Equal to',
        regionSelection: 'Select country',
      },
      headers: {
        provider: 'Provider',
        locations: 'Locations',
        keyAreas: 'Business segments',
        totalCapitalRatio: 'Total capital ratio',
        returnOnEquity: 'Return on equity',
        bankRatings: 'Ratings',
        headOffice: 'Headquarters',
        depositInsurance: 'Deposit guarantee',
        totalCapitalRatioCountry: 'Total capital ratio \n Country',
        totalCapitalRatioAverage: 'Total capital ratio \n Average',
        totalAssets: 'Balance sheet total',
        foundationLocation: 'Place of foundation',
        shareholder: 'Shareholders',
        subsidiaries: 'Subsidiaries',
        stockExchangeListed: 'Listed',
        employees: 'Employees',
        branchOffices: 'Branches',
        coreStory: 'Core business',
        mortgageVolume: 'Mortgage volume',
        coreCapitalRatio: 'Core capital ratio',
        marketCapitalization: 'Market cap',
        equity: 'Equity',
        leverageRatio: 'Leverage ratio',
        ratingParentCompany: 'Rating \n Parent company',
        date: 'Date',
        foundationYear: 'Year of foundation',
        returnEquityAverage: 'Average return on equity',
        creditVolume: 'Loan volume',
        investmentVolume: 'Investment volume',
        commissionIncome: 'Commission income',
        profit: 'Profit',
        savingsDeposits: 'Savings deposits',
        otherliabilities: 'Other liabilities',
        totalPayables: 'Total liabilities',
        lienOnRealProperty: 'Mortgage',
        receivablesFromBanks: 'Loans and advances to banks',
        receivablesCustomers: 'Loans and advances to customers',
        commissionExpenses: 'Commission expenses',
        netCommissionIncome: 'Commission income',
        commissionSurplus: 'Net commission income',
      },
    },
    demoUserAlert:
      'Only 10 of the 279 providers are shown in the beta version.',
  },
  contest: {
    subHeadlines: {
      buildingMoney:
        'New Mortgage Report: Comparing the Development of Interest Rates',
      callMoney:
        'New Call Money Report: Comparing the Development of Interest Rates',
      fixedDeposit:
        'New Fixed Deposit Report: Comparing the Development of Interest Rates',
    },
    explanations: {
      allReportsPage:
        'Here you can see your most recent graphs in chronological order. You can create new visualisations or adapt, export or duplicate existing ones. Compare the development of conditions at up to five providers and see how your competitors have performed on the market in recent weeks, months or years.',
      newReportPage: 'Select competitors and define a report period',
    },
    tooltips: {
      createPdf: 'Create a PDF',
      printPage: 'Print page',
    },
    form: {
      yourBank: 'Competitor',
      compareBank: 'Compare up to 5 providers.',
      effectiveInterestRate: 'Loan-to-Value Ratio of',
      period: 'Period',
      withoutLandRegistryCosts: 'Not including land registry costs',
      debitInterestRate: 'Fixed-Rate Period',
      selectCustomerGroup:
        'Select the customer group and whether intermediary offers should be included in the comparison:',
      providers: {
        nationwide: 'Nationwide providers',
        regional: 'Regional providers',
        intermediary: 'Intermediaries',
      },
      chartColors: {
        green: 'Green',
        red: 'Red',
        darkBlue: 'Dark blue',
        purple: 'Purple',
        yellow: 'Yellow',
        lime: 'Lime',
      },
      labels: {
        callMoney:
          'Include intermediary offers in the chart selection for maximum, average and minimum',
      },
      hints: {
        buildingMoney:
          'The loan relates to a purchase price of €250,000 in 2010, which\n is increased by €15,000 each year.\n Property value is €325,000 in 2015 and €400,000 in 2020\n Repayment was also increased accordingly to reflect average\n financing.',
      },
    },
    warnings: {
      titleIsRequired: 'Please enter the title of the report',
      demoUserPeriod:
        'It is not possible to select a period in the beta version. You will see data for the year 2020.',
      demoUser:
        'You will be able to see and export the data series for the complete time period selected in the full version.',
    },
  },
  wizard: {
    title: 'Create report',
    progressBar: {
      'Musterkunden erstellen': 'Create model customers',
      'Wettbewerber auswählen': 'Select competitors',
      'Anbieter auswählen': 'Select competitors',
      'Banken Auswahl': 'Select competitors',
      'Angebote filtern': 'Filter offers',
      'Produkte filtern': 'Filter offers',
      Selektion: 'Configure the calculation basis',
      Felderauswahl: 'Define conditions',
      Vorschau: 'Preview',
      Speichern: 'Save',
      Fertig: 'Finished',
      'Filter Auswahl': 'Filter offers',
      Spaltenauswahl: 'Define criteria',
      Laufzeiten: 'Select investment term',
    },
    checkBox: {
      labelAll: 'Select all/none',
    },
    groupNames: {
      Testbanken: 'Testbanken',
      'Bundesweite Banken': ' Nationwide banks',
      Vermittler: 'Intermediaries',
      'Bausparkassen/Versicherungen': 'Building societies/insurance companies',
      'Regionale Filialbanken': 'Regional banks',
      Nebenbedingungen: 'Auxiliary conditions ',
      'Auswahl zu Anschlussdarlehen': 'Rollover loan selection',
      'Seltene Kriterien': 'Rare criteria',
      Direktbanken: 'Direct banks',
      'Banken über Vermittler': 'Banks via intermediaries',
      'Bundesweite Filialbanken': 'Nationwide banks with branches',
      bankenRaisin: 'Banks via Raisin',
    },
    headlines: {
      secondStepTF: 'Select the information you want to see in your report',
      erstellen_sie_bis_zu_5_musterkunden_und_vergleichen_sie_sich_mit_ihren_wettbewerbern_konfigurieren_sie_jedes_muster_individuell_nach_ihrem_bedarf:
        'Create up to 5 model customers and compare yourself against the competition. Configure each model individually according to your requirements.',
      'sie_können_noch_weitere_vorgaben_machen:':
        'You can add other criteria as well:',
      'legen_sie_auf_basis_ihrer_auswahl_ein_automatisches_tägliches_tracking_an_ihre_auswertung_wird_jeden_tag_um_15:00_uhr_automatisch_gespeichert_sie_können_später_jeden_einzelnen_tag_ab_heute_einsehen':
        'Set up automatic daily tracking based on your selection. Your report will be saved automatically every day at 3 pm. You can then access reports for every day, starting with today’s date.',
      definieren_sie_schlagwörter_zur_einfachen_suche:
        'Add tags to speed up report retrieval later on',
      bitte_die_gewünschten_spalten_auswählen:
        'Select additional conditions (optional).',
      oder: 'or',
      'bitte die gewünschten spalten auswählen':
        'Select additional conditions (optional).',
      stellen_sie_hier_selektionen_zusammen_und_fügen_diese_der_auswahl_hinzu_es_können_bis_zu_5_verschiedene_selektionen_gemacht_werden:
        'Compile criteria here and add them to your selection. You can add up to 5 different criteria.',
      'legen_sie_auf_basis_ihrer_auswahl_ein_automatisches_tracking_an_ihre_auswertung_wird_jeden_tag_um_15:00_uhr_automatisch_gespeichert_sie_können_später_jeden_einzelnen_tag_ab_heute_einsehen':
        'Set up automatic tracking based on your selection. Your report will be saved automatically every day at 3 pm. You can then access reports for every day, starting with today’s date.',
      speichern_sie_die_auswertung_um_diese_momentaufnahme_von_heute_später_jederzeit_ansehen_herunterladen_oder_als_vorlage_für_weitere_auswertungen_nutzen_zu_können:
        'Save the report to be able to view today’s snapshot at any time, download it or use it as a template for other reports.',
    },
    subHeadlines: {
      secondStepTCheckboxes: 'Choose additional columns for your report',
      secondStepFCheckboxes:
        'Select the terms to be included in your evaluation',
    },
    fields: {
      Zielgruppe: 'Filter by target group',
      Kaufpreis: 'Purchase price',
      Darlehen: 'Loan',
      Beleihung: 'Loan-to-value ratio',
      Zinsbindung: 'Fixed-rate period',
      Tilgung: 'Repayment',
      Volltilgung: 'Full repayment',
      Aktionen: 'Actions',
      Nr: 'No.',
      'Automatische Abspeicherung': 'Automatic saving',
      'Einmalige Speicherung': 'Single save',
      Neukunden: 'New customers',
      Einlagensicherung: 'Deposit guarantee',
      'Automatische tägliche Abspeicherung': 'Auto-save',
      'Gewünschte Einlagensicherung': 'Preferred deposit guarantee',
    },
    placeholders: {
      'Beleihung (freie Eingabe)': 'Loan-to-value ratio (free entry)',
      'Kaufpreis  (freie Eingabe)': 'Purchase price (free entry)',
      'Darlehen  (freie Eingabe)': 'Loan (free entry)',
      'Tilgung  (freie Eingabe)': 'Repayment (free entry)',
      bitte_wählen: 'Please select ...',
      'Geben Sie ihrer Auswahl einen Namen': 'Give your selection a name',
      pleaseSelect: 'Please select',
    },
    warnings: {
      bitte_titel_eingeben: 'Please add a title.',
      mindestens_eine_bank_muss_ausgewahlt_werden:
        'You must select at least one provider.',
      step1: 'You must check at least one box.',
      bsugeldStep2: 'You must select 1–5 products.',
      mindestens_eine_bank_muss_ausgewählt_werden:
        'You must select at least one provider.',
      mindestens_eine_laufzeit_muss_ausgewählt_werden:
        'You must select at least one term.',
      kaufpreis_muss_angegeben_werden: 'You must state the purchase price.',
      darlehen_muss_angegeben_werden: 'You must state the loan.',
      'beleihung_muss_zwischen_10_%_und_105_%_sein':
        'The loan-to-value ratio  must be between 10% and 105%.',
      tilgung_darf_max_100_sein: 'Repayment must not exceed 100%.',
      zinsbindung_muss_ausgewählt_werden: 'Fixed-rate period must be selected.',
    },
    dialogTitles: {
      configureSampleCalculation: 'Configure sample calculation',
    },
    checkBoxValues: {
      'Basiszins-Bezug auf Beleihungsauslauf (3 Spalten)':
        'Base rate reference to loan-to-value ratio (3 columns)',
      'Rabatt bei höherer Tilgung (5 Spalten)':
        'Discount for higher repayment (5 columns)',
      'Höhe der Bereitstellungszinsen (3 Spalten)':
        'Commitment fee (3 columns)',
      'Rabatt oder Aufschlag bei unterschiedlichen Darlehensbeträgen (5 Spalten)':
        'Discount or premium on different loan amounts (5 columns)',
      'Möglichkeit und Aufschlag zu Sondertilgung (6 Spalten)':
        'Option and premium for unscheduled repayments (6 columns)',
      'Bedingungen zu Tilgungsveränderungen (7 Spalten)':
        'Conditions for changing the repayment schedule (7 columns)',
      'Vergabe von KfW-Darlehen (4 Spalten)': 'Award of KfW loans (4 columns)',
      'Kriterien zu Berufsgruppen (2 Spalten)':
        'Criteria for professional groups (2 columns)',
      'Forward-Aufschlag (10 Spalten)': 'Forward premium (10 columns)',
      'Ergänzungen zu Anschlussdarlehen (2 Spalten)':
        'Additions for follow-up loans (2 columns)',
      'Ergänzungen zu Forward-Darlehen (2 Spalten)':
        'Additions for forward loans (2 columns)',
      'Teilauszahlungen (5 Spalten)': 'Partial disbursements (5 columns)',
      'Objekt Einschränkungen (2 Spalten)': 'Property restrictions (2 columns)',
      'Angaben zu überwiegend vermieteten Objekten (2 Spalten)':
        'Information on predominantly rented properties (2 columns)',
      'Basiskonditionen (2 Spalten)': 'Basiskonditionen (2 columns)',
      'Tilgungssätze (5 Spalten)': 'Tilgungssätze (5 columns)',
      'Bereitstellungszinsen (2 Spalten)': 'Bereitstellungszinsen (2 columns)',
      'Bedingungen bei unterschiedlichen Darlehensbeträgen (5 columns)':
        'Bedingungen bei unterschiedlichen Darlehensbeträgen (5 columns)',
      'Sondertilgung (4 Spalten)': 'Sondertilgung (4 columns)',
      'Tilgungssatzwechsel (6 Spalten)': 'Tilgungssatzwechsel (6 columns)',
      'Förderdarlehen (4 Spalten)': 'Förderdarlehen (4 columns)',
      'Selbstständige und Freiberufler (2 columns)':
        'Selbstständige und Freiberufler (2 columns)',
      'Forward-Darlehen (9 Spalten)': 'Forward-Darlehen (9 columns)',
      'Teilauszahlungen (3 Spalten)': 'Teilauszahlungen (3 columns)',
      'Eigentumswohnungen (2 Spalten)': 'Eigentumswohnungen (2 columns)',
      'Mehrfamilienhaus (1 Spalte)': 'Mehrfamilienhaus (1 columns)',
    },
    dropDownValues: {
      täglich: 'daily',
      wöchentlich: 'weekly',
      monatlich: 'monthly',
    },
    tooltips: {
      produkt_zinsgutschrift:
        'Indicates the intervals at which returns are credited during the term.',
      produkt_zinsberechnung:
        // eslint-disable-next-line
        "Shows each competitor's interest calculation method (e.g., act/360, act/act).",
    },
  },
  nibc: {
    titles: {
      callMoney: 'NIBC report: Call money',
      fixedDeposit: 'NIBC report: Fixed deposit',
    },
  },
  kfw: {
    titles: {
      buildingMoney: 'KfW report: Mortgage',
    },
    explanation:
      'Changes to the specifications from 13/06/2018: €350,000 purchase price, €315,000/280,000 loan.\nSpecifications used for the calculation: 2% repayment, finished property, employee, no unscheduled repayments.\nThe increase in repayments from 1% to 2% came into effect on 01/09/2015.\n\nFor intermediaries, the mean values include all offers reported to FMH.',
    modal: {
      averageEffectiveInterestRateText:
        'Mean value, based on the best effective interest rates from the FMH index group',
      averageDebitInterestText:
        'Mean value, based on the best borrowing rates from the FMH index group',
      inFMHIndex:
        'All matching offers from intermediaries are taken into account in the FMH-IndeX. Only the best offer is included in the calculation for this value.',
    },
  },
  warnings: {
    oldReportTagesgeld: 'This report can no longer be updated.',
    zinsChartWarning: 'Please select a product',
    notFound: 'Not found',
    demoUserDeletingReport:
      'You are using a free trial version and are not allowed to delete reports. For an upgrade, please contact us at sales@fmh.de.',
    demoUserEditingReport:
      'You are using a free trial version and are not allowed to edit reports. For an upgrade, please contact us at sales@fmh.de.',
  },
};

export default en;
