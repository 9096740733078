import eachOfLimit from 'async/eachOfLimit';
import gql from 'graphql-tag';

import apolloProvider from '@/apollo/apollo-provider';
import { fetchConfigs, updateTags } from '@/utils/helpers/helpers';

import { instance } from '../../utils/index';
import { modifyAllReports } from '@/utils/helpers';

function reportType(type) {
  switch (type) {
    case 1:
      return 'tagesgeld';
    case 2:
      return 'festgeld';
    case 3:
      return 'baugeld';
    case 8:
      return 'tagesgeld';
    default:
      return 'tagesgeld';
  }
}

export default {
  async fetchAvailableArchiveDates({ commit }, reportType) {
    try {
      const resp = await instance.get(`/api/report-archive/${reportType}`);
      commit('setAvailableArchiveDates', resp?.data || []);
    } catch (e) {
      commit('setSnackBarData', {
        text: e instanceof Error ? e.message : JSON.stringify(e),
        show: true,
        timeout: 3000,
        dark: false,
        color: 'red',
      });
    }
  },
  async fetchTop({ commit }) {
    commit('loading', true);
    try {
      const QUERY_TAGESGELD = gql`
        query GetTagesgeld {
          tagesgeldTopZinsen {
            data {
              anbieterId
              anbietername
              zinssatz
            }
          }
        }
      `;
      const QUERY_FESTGELD = gql`
        query GetFestgeld($laufzeit: [Int]) {
          festgeldTopZinsen(laufzeit: $laufzeit) {
            data {
              anbieterId
              anbietername
              zinssatz1
              zinssatz2
              zinssatz3
            }
          }
        }
      `;
      const tagesgeld = await apolloProvider.defaultClient.query({
        query: QUERY_TAGESGELD,
      });
      const festgeld = await apolloProvider.defaultClient.query({
        query: QUERY_FESTGELD,
        variables: {
          laufzeit: [3, 6, 12],
        },
      });
      const festgeldLang = await apolloProvider.defaultClient.query({
        query: QUERY_FESTGELD,
        variables: {
          laufzeit: [24, 60, 120],
        },
      });

      const payload = {
        tagesgeldTopZinsen: tagesgeld?.data?.tagesgeldTopZinsen?.data || [],
        festgeldTopZinsen: festgeld?.data?.festgeldTopZinsen?.data || [],
        festgeldLang: festgeldLang?.data?.festgeldTopZinsen?.data || [],
      };
      commit('setDashboardTop', payload);
      commit('loading', false);
    } catch (e) {
      commit('loading', false);
    }
  },
  fetchXLSAllFields({ commit }, payload) {
    return instance.get(
      `api/report/compare/${payload.slug}/${payload.newId}/${payload.oldId}?all=true`
    );
  },
  compareReports({ commit }, payload) {
    commit('disabled', true);
    instance
      .get(
        `api/report/compare/${payload.slug}/${payload.newId}/${payload.oldId}`
      )
      .then((res) => {
        commit(
          'fetchSingleReportSuccess',
          Object.assign(res.data, {
            config: payload.config,
            title: payload.title,
            note: payload.note,
            meta: payload.meta,
            tags: updateTags(payload.tags, payload.reportType),
            slug: payload.slug,
            uid: payload.uid,
            reportType: payload.reportType,
          })
        );
      })
      .catch((e) => {
        commit('disabled', false);
        commit('error', true);
        console.log(e.response);
      });
  },
  fetchReportTypes() {
    return instance.get('/api/enums');
  },
  fetchLatestDataOfTheReport({ commit }, payload) {
    return instance.get(`/api/report/${payload.url}`, {
      params: { 'force-reload': true },
    });
  },
  fetchSnapshots({ commit }, payload) {
    commit('disabled', true);
    instance
      .get(`/api/report/${payload.url}`)
      .then((res) => {
        commit(
          'fetchSingleReportSuccess',
          Object.assign(res.data, {
            title: payload.title,
            note: payload.note,
            tags: updateTags(payload.tags, payload.reportType),
            meta: res.data.meta,
            slug: payload.slug,
            uid: payload.uid,
            allBanks: payload.allBanks,
          })
        );
      })
      .catch((e) => {
        commit('disabled', false);
        commit('error', true);
        console.log(e.response);
      });
  },
  fetchCopyReport({ commit }, id) {
    return instance.post(`/api/report-config/${id}/`);
  },
  fetchSingleReport({ commit }, payload) {
    commit('loading', true);
    instance
      .get(`/api/report-config/${payload}/`)
      .then((res) => {
        if (res.data.length) {
          commit('setFetchSingleReport', {
            ...res.data[0],
            allBanks:
              res.data[0].config?.allBanks?.filter((bank) =>
                bank.includes('_')
              ) || [],
            report_type: res.data[0].report_type,
            slug: res.data[0].slug,
            title: res.data[0].config.selection_title || res.data[0].title,
            uid: res.data[0].uid,
            config: res.data[0].config,
            tags: updateTags(res.data[0].tags, res.data[0].report_type),
          });
          const params = {};
          if (res.data?.[0]?.report_type && res.data[0].report_type !== 1) {
            Object.assign(params, { 'force-reload': true });
          }
          instance
            .get(`/api/report/${res.data[0].slug}`, {
              params,
            })
            .then((res) => {
              commit('loading', false);
              commit('error', false);
              commit('fetchSingleReportSuccess', res.data);
            })
            .catch((e) => {
              commit('loading', false);
              commit('error', true);
              console.log(e.response);
            });
        } else {
          commit('loading', false);
        }
      })
      .catch((e) => {
        if (e.response?.status !== 401) {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  async fetchPartsReports({ commit }, payload) {
    const { params, reportType, configKey } = payload;
    const controllerA = new AbortController();
    const controllerB = new AbortController();
    const signalA = controllerA.signal;
    const signalB = controllerB.signal;
    commit('addControllers', [controllerA, controllerB]);
    try {
      const { data } = await instance.get('/api/reports-config/', {
        signal: signalA,
        params,
      });

      const configs = {
        page: params.page,
        itemsPerPage: 8,
        next: data.next,
        previous: data.previous,
        count: data.count,
        results: (
          modifyAllReports(data?.results, reportType, configKey) || []
        ).map((item, index) => ({
          ...item,
          key: index,
          isSuccess: false,
          isLoading: true,
        })),
      };
      commit('mapSkeleton', { configs, reportType, configKey });
      await eachOfLimit(configs.results, 3, async (item, key) => {
        try {
          const fetchReportData = await instance
            .get(`/api/report/${item.slug}`, { signal: signalB })
            .then((res) => res.data)
            .catch((e) => {
              if (e && e.message !== 'canceled') {
                const payload = {
                  ...configs.results[key],
                  error: true,
                  isSuccess: true,
                  isLoading: false,
                  configKey,
                };
                commit('fetchAllReportsSuccess', {
                  payload,
                  reportType,
                });
              }
            });
          const payload = {
            ...fetchReportData,
            ...configs.results[key],
            isSuccess: true,
            isLoading: false,
            error: false,
            configKey,
          };
          commit('fetchAllReportsSuccess', {
            payload,
            reportType,
          });
        } catch (e) {
          console.log(e, 'err');
          commit('error', true);
        }
      });
    } catch (e) {
      if (e && e.message !== 'canceled') {
        commit('error', true);
        console.log(e.response);
      }
    }
  },
  async fetchAllReports({ commit }, reportType) {
    const controller = new AbortController();
    const signal = controller.signal;
    commit('addControllers', [controller]);

    //eslint-disable-next-line
    const key = reportType === 3 ? 'baugeld' : reportType === 2 ? 'festgeld' : 'tagesgeld';
    //eslint-disable-next-line
    const mutateLoading = reportType === 3 ? 'loadingAllReportsBaugeld' : reportType === 2 ? 'loadingAllReportsFestgeld' : 'loadingAllReportsTagesgeld';
    commit(mutateLoading, true);
    try {
      const allConfigs = await fetchConfigs(reportType, commit);
      const configs = {};
      Object.keys(allConfigs).forEach((key) => {
        const updatedResults = allConfigs[key].results.map((item, index) => ({
          ...item,
          tags: updateTags(item.tags, reportType),
          key: index,
          isSuccess: false,
          isLoading: true,
        }));
        Object.assign(configs, {
          [key]: { ...allConfigs[key], results: updatedResults },
        });
      });

      commit('mapSkeleton', { configs, reportType });
      commit('setUploadedReports', { [key]: true });
      for (const configKey of Object.keys(configs)) {
        await eachOfLimit(configs[configKey].results, 3, async (item, key) => {
          try {
            const fetchReportData = await instance
              .get(`/api/report/${item.slug}`, { signal: signal })
              .then((res) => res.data)
              .catch((e) => {
                if (e && e.message !== 'canceled') {
                  const payload = {
                    ...configs[configKey].results[key],
                    error: true,
                    isSuccess: true,
                    isLoading: false,
                    configKey,
                  };
                  commit('fetchAllReportsSuccess', {
                    payload,
                    reportType,
                  });
                }
              });
            if (fetchReportData) {
              const payload = {
                ...fetchReportData,
                ...configs[configKey].results[key],
                isSuccess: true,
                isLoading: false,
                error: false,
                configKey,
              };
              commit('fetchAllReportsSuccess', {
                payload,
                reportType,
              });
            }
          } catch (e) {
            commit(mutateLoading, false);
            commit('error', true);
          }
        });
      }

      commit(mutateLoading, false);
    } catch (e) {
      console.log(e, 'error');
      if (e && e.message !== 'canceled') {
        commit('setUploadedReports', { [key]: false });
        commit(mutateLoading, false);
        commit('error', true);
        console.log(e.response);
      }
    }
  },
  deleteReport({ commit }, payload) {
    return instance.delete(`/api/report-config/${payload.slug}/`);
  },
  async fetchPreviewReport({ commit }, payload) {
    commit('loadingPreview', true);
    const postController = new AbortController();
    const getController = new AbortController();
    const postSignal = postController.signal;
    const getSignal = getController.signal;
    commit('addControllers', [postController]);
    await instance
      .post('/api/report-config/', payload, {
        params: { preview: true },
        signal: postSignal,
      })
      .then((res) => {
        if (res.data) {
          commit('fetchPreviewReportSuccess', res.data);
          commit('addControllers', [getController]);
          instance
            .get(`/api/report/${res.data.slug}`, {
              params: { 'force-reload': true },
              signal: getSignal,
            })
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              if (e.message !== 'canceled') {
                commit('loadingPreview', false);
                commit('error', true);
                console.log(e.response);
              }
            });
        }
      })
      .catch((e) => {
        if (e.response?.status !== 401 && e.message !== 'canceled') {
          commit('loadingPreview', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchUpdatedReport({ commit }, payload) {
    const putController = new AbortController();
    const getController = new AbortController();
    const putSignal = putController.signal;
    const getSignal = getController.signal;
    commit('addControllers', [putController]);
    commit('loadingPreview', true);
    instance
      .put(`/api/report-config/${payload.slug}/`, payload, {
        signal: putSignal,
      })
      .then((res) => {
        if (res) {
          commit('addControllers', [getController]);
          commit('setUpdateReport', res.data);
          instance
            .get(`/api/report/${res.data.slug}`, {
              params: { 'force-reload': true },
              signal: getSignal,
            })
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('updateReportSuccess', {
                  payload: res.data,
                  editReport: true,
                });
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              if (e.message !== 'canceled') {
                commit('loadingPreview', false);
                commit('error', true);
                console.log(e);
              }
            });
        }
      })
      .catch((e) => {
        if (e.response?.status !== 401 && e.message !== 'canceled') {
          commit('loadingPreview', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  sendUpdatedExistingReport({ commit }, data) {
    commit('loadingPreview', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];
    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );
    const putController = new AbortController();
    const getController = new AbortController();
    const putSignal = putController.signal;
    const getSignal = getController.signal;
    commit('addControllers', [putController]);
    instance
      .put(`/api/report-config/${rest.slug}/`, payload, { signal: putSignal })
      .then((res) => {
        if (res.data) {
          commit('setUpdateExistingReport', res.data);
          commit('addControllers', [getController]);
          instance
            .get(`/api/report/${res.data.slug}`, {
              params: { 'force-reload': true },
              signal: getSignal,
            })
            .then((res) => {
              if (res.data) {
                commit('setPreviewReport', res.data);
                commit('updateReportSuccess', {
                  editReport: true,
                });
                commit('loadingPreview', false);
                commit('error', false);
              }
            })
            .catch((e) => {
              if (e.message !== 'canceled') {
                commit('loadingPreview', false);
                commit('error', true);
                console.log(e.response);
              }
            });
        }
      })
      .catch((e) => {
        if (e.response?.status !== 401 && e.message !== 'canceled') {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  async updateReport({ commit }, data) {
    const putController = new AbortController();
    const getController = new AbortController();
    const putSignal = putController.signal;
    const getSignal = getController.signal;
    commit('addControllers', [putController]);
    commit('loading', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];

    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );
    try {
      const res = await instance.put(
        `/api/report-config/${rest.slug}/`,
        payload,
        { signal: putSignal }
      );
      if (res.data) {
        const slug = res.data.slug;
        const productType = reportType(res.data.report_type);
        commit('setUpdateReport', res.data);
        commit('addControllers', [getController]);
        await instance
          .get(`/api/report/${slug}`, { signal: getSignal })
          .then((res) => {
            if (res.data) {
              commit('updateReportSuccess', {
                payload: { ...res.data, ...payload },
                editReport: false,
                productType,
                slug,
              });
              commit('loading', false);
              commit('error', false);
              commit('uploadReportSuccess', true);
            }
          })
          .catch((e) => {
            if (e.message !== 'canceled') {
              commit('loading', false);
              commit('error', true);
              console.log(e.response);
            }
          });
      }
    } catch (e) {
      if (e.response?.status !== 401 && e.message !== 'canceled') {
        commit('loading', false);
        commit('error', true);
        console.log(e.response);
      }
    }
  },
  async updateExistingReport({ commit }, data) {
    const { config, field, ...rest } = data;
    const payload = {};

    if (field === 'note') {
      Object.assign(payload, { note: rest.note, config });
    }
    if (field === 'tags') {
      Object.assign(payload, { tags: rest.tags, config });
    }
    if (field === 'title') {
      Object.assign(payload, { title: rest.title, config });
    }

    try {
      await instance.patch(`/api/report-config/${rest.slug}/`, payload);
    } catch (e) {
      commit('error', true);
    }
  },
  async sendAndUpdateExistingReport({ commit }, data) {
    const putController = new AbortController();
    const getController = new AbortController();
    const putSignal = putController.signal;
    const getSignal = getController.signal;
    commit('addControllers', [putController]);
    commit('loading', true);
    const { config, ...rest } = data;
    const { tags, note, ...res } = config;
    const n = note ? note : null;
    const t = tags ? tags : [];

    const payload = Object.assign(
      rest,
      { config: res },
      { note: n, tags: t },
      {}
    );

    await instance
      .put(`/api/report-config/${payload.slug}/`, payload, {
        signal: putSignal,
      })
      .then((res) => {
        if (res.data) {
          const slug = res.data.slug;
          const productType = reportType(res.data.report_type);
          commit('addControllers', [getController]);
          instance
            .get(`/api/report/${slug}`, { signal: getSignal })
            .then((res) => {
              if (res.data) {
                commit('updateExistingReportSuccess', {
                  payload: { ...res.data, ...payload },
                  editReport: false,
                  productType,
                  slug,
                });
                commit('uploadReportSuccess', true);
              }
            })
            .catch((e) => {
              if (e.message !== 'canceled') {
                commit('loading', false);
                commit('error', true);
                console.log(e.response);
              }
            });
        }
      })
      .catch((e) => {
        if (e.response?.status !== 401 && e.message !== 'canceled') {
          commit('loading', false);
          commit('error', true);
          console.log(e.response);
        }
      });
  },
  fetchReportConfig({ commit }, id) {
    return instance.get(`/api/report-config/${id}/`);
  },
};
