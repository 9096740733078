import router from '@/router';

import {
  INTEREST_CREDIT_FESTGELD,
  IS_TRANSLATE_TO_EN,
} from '@/utils/constants';
import { mapGetters } from 'vuex';
import filters from '@/filters';

import { mappedInterestCalculation } from '@/utils/helpers';
import { mappedInterestCredit } from '@/utils/helpers/helpers';
import { numberWithCommas } from '@/utils/helpers/wettbwerb';

const mixins = {
  computed: {
    ...mapGetters({
      getAnbieter: 'anbieter/getAnbieter',
    }),
    providerCount() {
      return (
        (this.getAnbieter?.[1]?.length || 0) +
        (this.getAnbieter?.[2]?.length || 0) +
        (this.getAnbieter?.[4]?.length || 0)
      );
    },
    isShowLanguageSelection() {
      return String(IS_TRANSLATE_TO_EN) === 'true';
    },
    styleContent() {
      const height = window.innerHeight - 310;
      return {
        'min-height': `${height}px`,
      };
    },
    defaultTableStyles() {
      const height = window.innerHeight - 430;
      return {
        height: `${height}px`,
        overflow: 'hidden',
      };
    },
  },
  methods: {
    handleChangeKeyDown(e) {
      if (
        e.key !== 'Tab' &&
        e.key !== 'Backspace' &&
        e.key !== 'ArrowRight' &&
        e.key !== 'ArrowLeft' &&
        e.key !== '/' &&
        !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)
      ) {
        e.preventDefault();
      }
    },
    isShowCol(item, field) {
      if (this.reportType === 8 && !this.isForXLS) {
        if (item.produkt_isMain) {
          return item[field] ? item[field] : false;
        } else {
          return false;
        }
      } else {
        return item[field];
      }
    },
    dataInterestCredit: function (data) {
      if (Number(this.reportType) === 2) {
        return [0, 1, 2, 3, 4, 5].includes(data)
          ? this.$t(mappedInterestCredit(data, INTEREST_CREDIT_FESTGELD))
          : '';
      }
      return [0, 1, 2, 3, 4, 5].includes(data)
        ? this.$t(mappedInterestCredit(data))
        : '';
    },
    dataInterestCalculation(data) {
      return this.$t(mappedInterestCalculation(data));
    },
    colValue(item, field) {
      if (this.$i18n.locale === 'de') {
        return item[field];
      }
      if (`${field}_en` in item) {
        return item[`${field}_en`];
      }
      return item[field];
    },
    productValue: function (item, field) {
      const value = item[field];
      if (
        [1, 8].includes(Number(this.reportType)) &&
        +this.config?.banken_kundenkreis === 1
      ) {
        if (!value) {
          return this.$t('generalTable.noOffer');
        } else {
          return `${field}_en` in item ? item[`${field}_en`] : value || '-';
        }
      }
      if (
        [1, 8].includes(Number(this.reportType)) &&
        this.config?.banken_kundenkreis !== 1
      ) {
        return `${field}_en` in item ? item[`${field}_en`] : value || '-';
      }
      if (
        [1, 8].includes(Number(this.reportType)) &&
        !this.config?.hasOwnProperty('banken_kundenkreis')
      ) {
        return `${field}_en` in item ? item[`${field}_en`] : value || '-';
      }
      if (Number(this.reportType) === 2) {
        if (this.$i18n.locale === 'de') {
          return value || '-';
        }
        return `${field}_en` in item ? item[`${field}_en`] : value || '-';
      }
    },
    modifyRows(rows) {
      const mapped = rows.map((i) => {
        return {
          ...i,
          produkt_aktionszinssatzFormatted:
            typeof i.produkt_aktionszins === 'boolean'
              ? i.produkt_aktionszins
                ? this.$t('general.ja')
                : this.$t('general.nein')
              : i.produkt_aktionszinssatz
              ? `${numberWithCommas(i.produkt_aktionszinssatz)}%`
              : '-',
          produkt_maximalanlageProduktFormatted: i.produkt_maximalanlageProdukt
            ? i.produkt_maximalanlageProdukt.toString().length > 3
              ? `${i.produkt_maximalanlageProdukt.toLocaleString('de-DE')} €`
              : `${i.produkt_maximalanlageProdukt} €`
            : this.$t('common.generalTable.unlimited'),
          produkt_maximalanlageFormatted: i.produkt_maximalanlage
            ? i.produkt_maximalanlage.toString().length > 3
              ? `${i.produkt_maximalanlage.toLocaleString('de-DE')} €`
              : `${i.produkt_maximalanlage} €`
            : this.$t('common.generalTable.unlimited'),
          isAnbieterShowCol: this.isShowCol(i, 'anbieter_anbieterLogoUrl'),
          produkt_mindestanlage: this.isShowCol(i, 'produkt_mindestanlage')
            ? i.produkt_mindestanlage.toString().length > 3
              ? `${i.produkt_mindestanlage.toLocaleString('de-DE')} €`
              : `${i.produkt_mindestanlage} €`
            : '',
          produkt: this.isShowCol(i, 'produkt_produktname')
            ? this.productValue(i, 'produkt_produktname')
            : '',
          produkt_datumZinsaenderung: i.produkt_datumZinsaenderung
            ? this.$date(i.produkt_datumZinsaenderung).format('DD.MM.YYYY')
            : '',
          produkt_zinsberechnung:
            typeof i.produkt_zinsberechnung === 'number'
              ? this.dataInterestCalculation(i.produkt_zinsberechnung)
              : '',
          produkt_zinsgutschrift:
            typeof i.produkt_zinsgutschrift === 'number'
              ? this.dataInterestCredit(i.produkt_zinsgutschrift)
              : '',
          produkt_anschlussZinssatz: i.produkt_anschlussZinssatz
            ? `${i.produkt_anschlussZinssatz.toLocaleString(
                this.$i18n.locale
              )} %`
            : '-',
          produkt_abBetrag: i.produkt_abBetrag
            ? i.produkt_abBetrag.toLocaleString(this.$i18n.locale)
            : '-',
          produkt_zinsgarantie:
            'produkt_zinsgarantie' in i
              ? this.colValue(i, 'produkt_zinsgarantie')
              : '-',
        };
      });
      if (
        mapped.some(
          (i) =>
            i.hasOwnProperty('ausschlussKriterien_0_bemerkung') &&
            i.ausschlussKriterien_0_bemerkung
        )
      ) {
        return mapped.filter(
          (i) => !i.hasOwnProperty('ausschlussKriterien_0_bemerkung')
        );
      } else {
        return mapped;
      }
    },
    parsePeriod(formParam, date) {
      const von = formParam.zeitraumVon;
      const bis = formParam.zeitraumBis;
      const zeitraum = formParam.zeitraum;
      if (von && bis && zeitraum === 0) {
        return `${date(von).format('DD.MM.YYYY')} - ${date(bis).format(
          'DD.MM.YYYY'
        )}`;
      }
      return filters.zeitraumJahre(zeitraum);
    },
    parseDate: function (str) {
      try {
        const parts = str.match(/(\d+)/g);
        return new Date(parts[2], parts[1] - 1, parts[0]);
      } catch (error) {
        return null;
      }
    },
    formatDate: function (d) {
      return d.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    formatProzent: function (value, digits) {
      return (
        value.toLocaleString('de-DE', { minimumFractionDigits: digits }) +
        ' ' +
        SETTINGS.prozentZeichen
      );
    },
    hexToRGB: function (hex) {
      return `rgb(${+(+`0x${hex[1]}${hex[2]}`)},${+(+`0x${hex[3]}${hex[4]}`)},${+(+`0x${hex[5]}${hex[6]}`)})`;
    },
    hexToRGBA: function (hex, a) {
      const r = `0x${hex[1]}${hex[2]}`;
      const g = `0x${hex[3]}${hex[4]}`;
      const b = `0x${hex[5]}${hex[6]}`;
      return 'rgba(' + +r + ', ' + +g + ', ' + +b + ', ' + a.toFixed(2) + ')';
    },
    modifyString(str) {
      return str
        .toLowerCase()
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll('!', '')
        .replace(/ /g, '_');
    },
    confirmModalText(locale, title) {
      return locale === 'de'
        ? `Möchten Sie wirklich '${title}' löschen? \n Diese Aktion kann nicht rückgängig gemacht werden.`
        : `Are you sure you want to delete '${title}'? \n This action cannot be undone.`;
    },
    processTableRows(headers, rows) {
      const filtered = (headers || []).map((item) => {
        if (item.value === 'a_anbieter') {
          const { children, ...rest } = item;
          return rest;
        }
        return item;
      });

      const allHeaders = filtered
        .map((h) => {
          if (!h.children) return { value: h.value };
          const last = h.children.length - 1;
          return h.children?.map((c, index) => {
            if (last === index) {
              return {
                ...{ value: c.value },
                ...{ className: 'border-child-right' },
              };
            } else {
              return { value: c.value };
            }
          });
        })
        .flat(1);

      const updatedValues = rows.map((row) => {
        let o = { ...row };
        allHeaders.map((header) => {
          if (`${header.value[0]}_produkt_effektivzins_difference` in row) {
            o = {
              ...o,
              [`${header.value[0]}_produkt_effektivzins_difference`]: Number(
                row[`${header.value[0]}_produkt_effektivzins_difference`]
              ).toFixed(2),
            };
          }
          if (`${header.value[0]}_produkt_sollzins_difference` in row) {
            o = {
              ...o,
              [`${header.value[0]}_produkt_sollzins_difference`]: Number(
                row[`${header.value[0]}_produkt_sollzins_difference`]
              ).toFixed(2),
            };
          }
          if (!row[header.value]) {
            o = { ...o, [header.value]: '-' };
          }
          if (
            header.value.includes('produkt_effektivzins_old') &&
            row[`${header.value[0]}_produkt_effektivzins_old`] !== 0 &&
            !isFinite(+row[`${header.value[0]}_produkt_sollzins`])
          ) {
            o = {
              ...o,
              ...{ [header.value]: row[`${header.value[0]}_produkt_sollzins`] },
            };
          }
          if (
            header.value.includes('produkt_effektivzins_old') &&
            row[`${header.value[0]}_produkt_effektivzins_old`] === 0 &&
            !isFinite(row[`${header.value[0]}_ausschlussKriterien_0_bemerkung`])
          ) {
            o = {
              ...o,
              [`${header.value[0]}_produkt_effektivzins_old`]:
                row[`${header.value[0]}_ausschlussKriterien_0_bemerkung`],
            };
          }

          if (
            header.value.includes('produkt_effektivzins') &&
            !isFinite(+row[`${header.value[0]}_produkt_effektivzins_old`]) &&
            isFinite(+row[`${header.value[0]}_produkt_sollzins`]) &&
            isFinite(row[`${header.value[0]}_ausschlussKriterien_0_bemerkung`])
          ) {
            o = {
              ...o,
              [`${header.value[0]}_produkt_effektivzins_old`]: '',
            };
          }
          if (
            header.value.includes('produkt_effektivzins') &&
            isFinite(+row[`${header.value[0]}_produkt_effektivzins_old`]) &&
            !isFinite(+row[`${header.value[0]}_produkt_effektivzins`]) &&
            isFinite(row[`${header.value[0]}_ausschlussKriterien_0_bemerkung`])
          ) {
            const result =
              +row[`${header.value[0]}_produkt_effektivzins_old`] +
              +row[`${header.value[0]}_produkt_effektivzins_difference`];
            o = {
              ...o,
              ...{
                [`${header.value[0]}_produkt_effektivzins`]:
                  String(result.toFixed(2)) || '-',
              },
            };
          }
        });
        return o;
      });
      return updatedValues.map((row) => {
        const cols = [];
        for (const element of allHeaders) {
          const key = `${element.value}_difference`;
          const icon = `${element.value}_trend`;
          if (
            !element.value.includes('produkt') &&
            !element.value.includes('anbieter')
          ) {
            const col = {
              isDynamic: true,
              value: row[element.value] || '',
              header: element.value,
              className: element.className,
              compare: row.hasOwnProperty(`${element.value}_difference`)
                ? { [key]: row[key], [icon]: row[icon] }
                : null,
            };
            if (`${element.value}_en` in row) {
              Object.assign(col, {
                value_en: row[`${element.value}_en`],
              });
            }
            cols.push(col);
          } else {
            const col = {
              isDynamic: false,
              value: row[element.value] || '',
              header: element.value,
              className: element.className,
              compare: row.hasOwnProperty(`${element.value}_difference`)
                ? { [key]: row[key], [icon]: row[icon] }
                : null,
            };
            if (`${element.value}_en` in row) {
              Object.assign(col, {
                value_en: row[`${element.value}_en`],
              });
            }
            cols.push(col);
          }
        }
        return cols;
      });
    },
    getHeadersForXLS(headers, rows) {
      return headers.map((header) => {
        if (header?.children) {
          const childrenHeaders = [];
          const filtered = header.children.filter(
            (item) => !item.value.includes('anbieter_kreditgeber')
          );
          filtered.forEach((item) => {
            if (item.text) {
              childrenHeaders.push({
                text: item.text,
                text_en: item.text_en,
                value: item.value,
              });
              if (rows.some((row) => row.hasOwnProperty(`${item.value}_old`))) {
                childrenHeaders.push({
                  text: `${item.text} \n alt`,
                  text_en: `${item.text_en} old`,
                  value: `${item.value}_old`,
                });
              }
              if (
                rows.some((row) =>
                  row.hasOwnProperty(`${item.value}_difference`)
                )
              ) {
                childrenHeaders.push({
                  text: `${item.text} \n diff`,
                  text_en: `${item.text_en} \n diff`,
                  value: `${item.value}_difference`,
                });
              }
            }
          });
          return {
            ...header,
            children: childrenHeaders,
          };
        }
        return header;
      });
    },
    setMenuItems(report, page) {
      if (page === 'singleReport') {
        const menuItems = [
          {
            title: 'common.noteTagsHint.note',
            icon: 'mdi-note-outline ',
            cardTitle: 'common.noteTagsHint.yourNote',
            note: report?.note,
            class: 'note',
            cardClass: 'note-card',
            model: false,
          },
          {
            title: 'common.noteTagsHint.tags',
            icon: 'mdi-tag-outline',
            cardTitle: 'common.noteTagsHint.tags',
            tags: (report?.tags || []).map((tag) => {
              if (tag.includes('deutsche') || tag.includes('erweiterte')) {
                return `${tag?.charAt(0).toUpperCase()}${tag?.slice(1)}`;
              }
              return tag;
            }),
            class: 'tags',
            cardClass: 'tags-card',
            model: false,
            nudgeLeft: 90,
          },
          /*{
            title: 'common.noteTagsHint.hint',
            icon: 'mdi-clock',
            cardTitle: 'common.noteTagsHint.hint',
            class: 'hint',
            hint: 'common.noteTagsHint.hintDescription',
            cardClass: 'hint-card',
            model: !!JSON.parse(localStorage.getItem('hint') || 'false'),
          },*/
        ];
        if (+report?.report_type === 1) {
          return menuItems.filter((i) => i.class !== 'hint');
        }
        return menuItems;
      }
      return [
        {
          title: 'common.noteTagsHint.note',
          icon: 'mdi-note-text',
          cardTitle: 'common.noteTagsHint.yourNote',
          note: report?.note,
          class: 'note',
          cardClass: 'note-card',
          model: false,
        },
      ];
    },
    reloadPage() {
      window.location.reload();
    },
    back(to) {
      if (window.location.pathname !== to) {
        router.push(to).catch(() => {});
      }
    },

    updateMenuItemsModel(title, menuItems, isTrue) {
      return menuItems.map((item) => {
        if (item.title === title) {
          return {
            ...item,
            model: isTrue || false,
          };
        }
        return {
          ...item,
          model: false,
        };
      });
    },
  },
};

export default mixins;
