import getters from '@/store/app/getters';
import mutations from '@/store/app/mutations';

const state = {
  isOpenSidebar: true,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
