<template>
  <v-app-bar app clipped-left flat id="appHeader">
    <div class="header-content">
      <img
        src="/assets/img/fmh-logo.svg"
        alt="Logo"
        width="200"
        height="40"
        @click="handleNavigate"
      />
      <div>
        <LanguageSelection v-if="isShowLanguageSelection" class="pr-3" />
        <div>
          <v-menu
            nudge-bottom="30"
            bottom
            left
            :attach="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ props }">
              <v-btn dark icon v-bind="props">
                <v-icon size="30" :color="color">mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon class="base-normal-color">mdi-account</v-icon>
                  </template>
                  <v-list-item-title class="base-normal-color">{{
                    userData
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="color"
                  variant="text"
                  @click="logOut"
                  class="base-normal-color"
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';

import LanguageSelection from '../fmh_components/common/LanguageSelection/LanguageSelection.vue';

import { BASE_COLOR } from '@/utils/constants';
import mixins from '@/mixins';

export default defineComponent({
  name: 'TopBar',
  components: { LanguageSelection },
  mixins: [mixins],
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
    mini: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      color: BASE_COLOR,
      title: 'header.bankingPortal',
    };
  },
  watch: {
    $route: {
      handler: function () {
        const id = this.$router.currentRoute.value.params.id;
        switch (this.$router.currentRoute.value.fullPath) {
          case '/':
            return (this.title = 'dashboard.headerTitle');
          case '/tagesgeld':
            return (this.title = 'liveData.headerTitle.callMoney');
          case '/festgeld':
            return (this.title = 'liveData.headerTitle.fixedDeposit');
          case '/baugeld':
            return (this.title = 'liveData.headerTitle.buildingMoney');
          case '/baugeld/wizard':
            return (this.title = 'Wizard Baugeld');
          case '/festgeld/wizard':
            return (this.title = 'Wizard Festgeld');
          case '/tagesgeld/wizard':
            return (this.title = 'Wizard Tagesgeld');
          case '/wettbewerb-baugeld':
            return (this.title = 'Wettbewerb');
          case '/wettbewerb-festgeld':
            return (this.title = 'Wettbewerb');
          case '/wettbewerb-tagesgeld':
            return (this.title = 'Wettbewerb');
          case '/spezialabonnenten-tagesgeld':
            return (this.title = 'NIBC Tagesgeld');
          case '/spezialabonnenten-festgeld':
            return (this.title = 'NIBC Festgeld');
          case '/kfw-baugeld':
            return (this.title = 'KfW Baugeld');
          case '/banking-profile':
            return (this.title = 'bankingProfile.headerTitle');
          case '/banking-profile/add-new-report':
            return (this.title = 'bankingProfile.headerTitle');
          case `/banking-profile/report/${id}`:
            return (this.title = 'bankingProfile.headerTitle');
          case `/banking-profile/bank-details/${id}`:
            return (this.title = 'bankingProfile.headerTitle');
          default:
            return (this.title = 'dashboard.headerTitle');
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    }),
    userData() {
      return this.user?.fullName || '';
    },
  },
  methods: {
    ...mapActions({
      logOutSession: 'auth/logOutSession',
    }),
    ...mapMutations({
      clearResult: 'reports/clearResult',
      resetError: 'reports/resetError',
      resetWizardError: 'wizard/resetError',
      clearDatenaboData: 'datenabo/clearDatenaboData',
      resetStateBProfile: 'bankingProfile/resetState',
      resetWettbewerb: 'wettbewerb/resetState',
    }),
    handleNavigate() {
      if (this.$router.currentRoute.value.path !== '/dashboard') {
        this.$router.push('/dashboard');
      }
    },
    logOut() {
      this.clearResult();
      this.clearDatenaboData();
      this.resetStateBProfile();
      this.resetWettbewerb();
      this.logOutSession().then(() => {
        this.$router.push('/login');
      });
      this.$i18n.locale = 'de';
      this.$emit('change-drawer', { drawer: true, mini: false });
    },
  },
  mounted() {
    const language = JSON.parse(localStorage.getItem('language') || '""');
    if (language) {
      this.$i18n.locale = language;
    }
  },
});
</script>

<style lang="scss" scoped>
.icon-container {
  margin-right: 20px;
}
.header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  flex-wrap: wrap;
  img:hover {
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
  }
}
.languages-container {
  [class~='col'] {
    padding-top: 0;
    padding-bottom: 0;
    [class~='v-input'] {
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.languages {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  & > .flag-icon {
    margin-right: 5px;
  }
}
</style>
