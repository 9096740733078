import { updateField } from 'vuex-map-fields';
import {
  defaultState,
  DEFAULT_CHART_PARAM_BAUGELD,
  DEFAULT_CHART_PARAM_FESTGELD,
  DEFAULT_FORM_PARAM_BAUGELD,
  DEFAULT_FORM_PARAM_FESTGELD,
} from '@/store/param/index';

export default {
  updateField,
  resetParamTagesgeld(state) {
    state.resultParam = {
      showResult: false,
    };
  },
  updateCopyForm(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state[key] = val;
  },
  updateChartParam(state, { path, value }) {
    let paths = path.split('.');
    state[paths[0]] = { ...state[paths[0]], [paths[1]]: value };
  },
  setValueMaxAvgMin(state, payload) {
    const [[key, val]] = Object.entries(payload);
    state[key] = {
      ...state[key],
      ...{
        chartMinValue: val,
        chartMaxValue: val,
        chartAvgValue: val,
      },
    };
  },
  updateVermittlerangebote(state, payload) {
    const { field, ...rest } = payload;

    state[field].vermittlerangebote = {
      value: rest.value,
      selected: rest.selected,
    };
    localStorage.setItem(field, JSON.stringify(state[field]));
  },
  saveParamHypoteken(state) {
    state.forceUpdate = false;
    localStorage.setItem(
      'formParamHypotheken',
      JSON.stringify(state.formParamHypotheken)
    );
    localStorage.setItem(
      'chartParamHypotheken',
      JSON.stringify(state.chartParamHypotheken)
    );
  },
  saveParamFestGeld(state) {
    state.forceUpdate = false;
    localStorage.setItem(
      'formParamFestGeld',
      JSON.stringify(state.formParamFestGeld)
    );
    localStorage.setItem(
      'chartParamFestGeld',
      JSON.stringify(state.chartParamFestGeld)
    );
  },
  setFormParamHypotheken(state, param) {
    state.formParamHypotheken = { ...state.formParamHypotheken, ...param };
    state.copyFormParamHypotheken = {
      ...state.copyFormParamHypotheken,
      ...param,
    };
  },
  setFormParamFestGeld(state, param) {
    state.formParamFestGeld = { ...state.formParamFestGeld, ...param };
    state.copyFormParamFestGeld = { ...state.copyFormParamFestGeld, ...param };
  },
  setChartParamHypotheken(state, param) {
    state.chartParamHypotheken = { ...state.chartParamHypotheken, ...param };
  },
  setChartParamFestGeld(state, param) {
    state.chartParamFestGeld = { ...state.chartParamFestGeld, ...param };
  },
  setFormParam(state, param) {
    state.formParam = { ...state.formParam, ...param };
    state.copyFormParam = { ...state.copyFormParam, ...param };
  },
  setChartParam(state, param) {
    state.chartParam = param;
  },
  setResultParam(state, param) {
    state.resultParam = param;
  },
  setFieldValue(state, { path, value, hypotheken, festGeld }) {
    let paths = path.split('.');
    if (!hypotheken && !festGeld) {
      if (paths.length === 2) {
        state[paths[0]] = { ...state[paths[0]], [paths[1]]: value };
      } else {
        state[paths[0]] = { ...state[paths[0]], [paths[0]]: value };
      }
      localStorage.setItem('formParam', JSON.stringify(state.formParam));
      localStorage.setItem('chartParam', JSON.stringify(state.chartParam));
      localStorage.setItem('resultParam', JSON.stringify(state.resultParam));
    }
    if (hypotheken && !festGeld) {
      if (paths[0] === 'chartParamHypotheken') {
        const result = {
          ...state.chartParamHypotheken,
          [paths[1]]: value,
        };
        state.chartParamHypotheken = result;
        localStorage.setItem('chartParamHypotheken', JSON.stringify(result));
      } else {
        const result = {
          ...state.formParamHypotheken,
          [paths[1]]: value,
        };
        state.formParamHypotheken = result;
        localStorage.setItem('formParamHypotheken', JSON.stringify(result));
      }
    }
    if (festGeld && !hypotheken) {
      if (paths[0] === 'chartParamFestGeld') {
        const result = {
          ...state.chartParamFestGeld,
          [paths[1]]: value,
        };
        state.chartParamFestGeld = result;
        localStorage.setItem('chartParamFestGeld', JSON.stringify(result));
      } else {
        const result = {
          ...state.formParamFestGeld,
          [paths[1]]: value,
        };
        state.formParamFestGeld = result;
        localStorage.setItem('formParamFestGeld', JSON.stringify(result));
      }
    }
  },
  resetStateHypotheken(state) {
    Object.assign(
      state,
      {
        formParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
        copyFormParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
      },
      {
        chartParamHypotheken: { ...DEFAULT_CHART_PARAM_BAUGELD },
      },
      {}
    );
    state.forceUpdate = true;
    localStorage.removeItem('formParamHypotheken');
  },
  resetStateFestGeld(state) {
    Object.assign(
      state,
      {
        formParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
        copyFormParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
      },
      {
        chartParamFestGeld: { ...DEFAULT_CHART_PARAM_FESTGELD },
      },
      {}
    );
    state.forceUpdate = true;
    localStorage.removeItem('formParamFestGeld');
  },
  resetState(state) {
    Object.assign(state, defaultState(), {});
    localStorage.removeItem('formParam');
    localStorage.removeItem('chartParam');
    localStorage.removeItem('resultParam');
  },
};
