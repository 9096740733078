import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-main__wrap" }
const _hoisted_2 = {
  key: 1,
  class: "public"
}
const _hoisted_3 = { class: "v-main__wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { id: "app" }, {
    default: _withCtx(() => [
      (!_ctx.publicPages.includes(_ctx.currentPath) && _ctx.authorized)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["not-public", {
        'scroll-pages': _ctx.scrollPages.includes(_ctx.$router.currentRoute.value.name),
      }])
          }, [
            _createVNode(_component_TopBar, {
              drawer: _ctx.drawer,
              mini: _ctx.mini,
              onChangeDrawer: _ctx.handleChangeDrawer
            }, null, 8, ["drawer", "mini", "onChangeDrawer"]),
            _createVNode(_component_Navigation, {
              mini: _ctx.mini,
              drawer: _ctx.drawer,
              onChangeDrawer: _ctx.handleChangeDrawer
            }, null, 8, ["mini", "drawer", "onChangeDrawer"]),
            _createVNode(_component_v_main, {
              class: _normalizeClass({
          'main-content': _ctx.validatePath,
        })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_v_container, {
                    fluid: "",
                    class: _normalizeClass(["app-content", {
              'overflow-visible':
                _ctx.$router.currentRoute.name === 'chart-generator',
            }])
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_router_view, {
                        drawerApp: _ctx.drawer,
                        onChangeDrawer: _ctx.handleChangeDrawer,
                        key: _ctx.$route.fullPath
                      }, null, 8, ["drawerApp", "onChangeDrawer"]))
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createVNode(_component_v_footer, { class: "footer" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Footer)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["class"])
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.publicPages.includes(_ctx.currentPath))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (
          ['reset-password', 'login', 'forgot-password'].includes(
            _ctx.$router.currentRoute.value.name
          )
        )
              ? (_openBlock(), _createBlock(_component_v_main, { key: 0 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_router_view),
                      _createVNode(_component_v_footer, { class: "fixed-footer" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Footer)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}