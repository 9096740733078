import axios from 'axios';
import { URLS } from '@/utils/constants';

export default {
  loadChartDataFestGeldFromLocalStorage({ commit }) {
    let data = null;
    const chartData = localStorage.getItem('chartDataFestGeld');
    if (chartData) {
      try {
        data = JSON.parse(chartData);
        commit('setChartDataFestGeldFromLocalStorage', data);
      } catch (e) {
        localStorage.removeItem('chartDataFestGeld');
      }
    }
  },
  loadChartDataHypothekenFromLocalStorage({ commit }) {
    let data = null;
    const chartData = localStorage.getItem('chartDataHypotheken');
    if (chartData) {
      try {
        data = JSON.parse(chartData);
        commit('setChartDataHypothekenFromLocalStorage', data);
      } catch (e) {
        localStorage.removeItem('chartDataHypotheken');
      }
    }
  },
  async loadChartDataFestGeld({ commit }, payload) {
    const { zeitraum, zeitraumBis, zeitraumVon, vermittlerangebote, ...rest } =
      payload;
    let param = {};
    if (zeitraum !== 0) {
      param = Object.assign(rest, {
        zeitraum: zeitraum,
        vermittlerangebote: vermittlerangebote.value,
      });
    }
    if (zeitraum === 0 && (zeitraumBis === null || zeitraumVon === null)) {
      param = Object.assign(rest, {
        zeitraum: zeitraum,
        vermittlerangebote: vermittlerangebote.value,
      });
    }
    if (zeitraum === 0 && (zeitraumBis !== null || zeitraumVon !== null)) {
      param = Object.assign(
        rest,
        {
          vermittlerangebote: vermittlerangebote.value,
        },
        { zeitraumBis: zeitraumBis },
        { zeitraumVon: zeitraumVon }
      );
    }

    await axios
      // festgeld
      .get(`${URLS[5]}index`, {
        params: param,
      })
      .then((response) => {
        commit('setChartDataFestGeld', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadChartDataHypotheken({ commit }, payload) {
    const { zeitraum, zeitraumBis, zeitraumVon, ...rest } = payload;
    let param = {};
    if (zeitraum !== 0) {
      param = Object.assign(rest, { zeitraum: zeitraum });
    }
    if (zeitraum === 0 && (zeitraumBis === null || zeitraumVon === null)) {
      param = Object.assign(rest, { zeitraum: zeitraum });
    }
    if (zeitraum === 0 && (zeitraumBis !== null || zeitraumVon !== null)) {
      param = Object.assign(
        rest,
        { zeitraumBis: zeitraumBis },
        { zeitraumVon: zeitraumVon }
      );
    }

    await axios
      // baugeld
      .get(`${URLS[6]}index`, {
        params: param,
      })
      .then((response) => {
        commit('setChartDataHypotheken', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async loadChartDataAsync({ commit }, param) {
    const { vermittlerangebote, ...rest } = param;
    const modified = Object.assign(rest, {
      vermittlerangebote: vermittlerangebote.value,
    });
    await axios
      // tagesgeld
      .get(`${URLS[4]}index`, { params: modified })
      .then((response) => {
        commit('setChartData', response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
