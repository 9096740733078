import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08305b14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageSelection = _resolveComponent("LanguageSelection")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    app: "",
    "clipped-left": "",
    flat: "",
    id: "appHeader"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: "/assets/img/fmh-logo.svg",
          alt: "Logo",
          width: "200",
          height: "40",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleNavigate && _ctx.handleNavigate(...args)))
        }),
        _createElementVNode("div", null, [
          (_ctx.isShowLanguageSelection)
            ? (_openBlock(), _createBlock(_component_LanguageSelection, {
                key: 0,
                class: "pr-3"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(_component_v_menu, {
              "nudge-bottom": "30",
              bottom: "",
              left: "",
              attach: true,
              "close-on-content-click": false
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  dark: "",
                  icon: ""
                }, props), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, {
                      size: "30",
                      color: _ctx.color
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-account-outline")
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ]),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item, null, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_v_icon, { class: "base-normal-color" }, {
                              default: _withCtx(() => [
                                _createTextVNode("mdi-account")
                              ]),
                              _: 1
                            })
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, { class: "base-normal-color" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.userData), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: _ctx.color,
                          variant: "text",
                          onClick: _ctx.logOut,
                          class: "base-normal-color"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Logout ")
                          ]),
                          _: 1
                        }, 8, ["color", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}