<template>
  <v-btn-toggle
    v-model="modelSource"
    color="selected"
    mandatory
    class="languages-selection-btns"
    :variant="
      ['reset-password', 'login', 'forgot-password'].includes(
        $router.currentRoute.value.name
      )
        ? 'flat'
        : 'outlined'
    "
  >
    <v-btn size="small" value="de">DE</v-btn>
    <v-btn size="small" value="en">EN</v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BASE_COLOR } from '@/utils/constants';

export default defineComponent({
  name: 'LanguageSelection',
  computed: {
    modelSource: {
      get: function () {
        return this.model;
      },
      set: function (val: string) {
        this.model = val;
        localStorage.setItem('language', JSON.stringify(val));
        this.$i18n.locale = val;
        this.$vuetify.locale.current = val;
      },
    },
  },
  data() {
    return {
      color: BASE_COLOR,
      model: 'de',
      languages: [
        { value: 'en', title: 'English' },
        { value: 'de', title: 'German' },
      ],
    };
  },
  mounted() {
    const language = JSON.parse(localStorage.getItem('language') || '""');
    if (language) {
      this.model = language;
      this.$i18n.locale = language;
      this.$vuetify.locale.current = language;
    }
  },
});
</script>
<style lang="scss" scoped>
.languages-selection-btns {
  height: 28px !important;
}
</style>
