import { createI18n } from 'vue-i18n';

import de from '@/utils/locales/de';
import en from '@/utils/locales/en';

const messages = {
  en,
  de,
};

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  allowComposition: true,
  globalInjection: true,
  messages,
});

export default i18n;
