export default {
  saveParam({ state }) {
    localStorage.setItem('formParam', JSON.stringify(state.formParam));
    localStorage.setItem('chartParam', JSON.stringify(state.chartParam));
    localStorage.setItem('resultParam', JSON.stringify(state.resultParam));
  },
  loadParamFestGeld({ commit }) {
    let param = null;
    let chartData = null;
    const formParam = localStorage.getItem('formParamFestGeld');
    const chartParam = localStorage.getItem('chartParamFestGeld');
    if (chartParam) {
      try {
        chartData = JSON.parse(chartParam);
        commit('setChartParamFestGeld', chartData);
      } catch (e) {
        localStorage.removeItem('chartParamFestGeld');
      }
    }
    if (formParam) {
      try {
        param = JSON.parse(formParam);
        if (param.kundenkreis === 1) {
          param.kundenkreis = 0;
        }
        commit('setFormParamFestGeld', param);
      } catch (e) {
        localStorage.removeItem('formParamFestGeld');
      }
    }
  },
  loadParamHypotheken({ commit }) {
    let param = null;
    let chartData = null;
    const formParam = localStorage.getItem('formParamHypotheken');
    const chartParam = localStorage.getItem('chartParamHypotheken');
    if (chartParam) {
      try {
        chartData = JSON.parse(chartParam);
        commit('setChartParamHypotheken', chartData);
      } catch (e) {
        localStorage.removeItem('chartParamHypotheken');
      }
    }
    if (formParam) {
      try {
        param = JSON.parse(formParam);
        commit('setFormParamHypotheken', param);
      } catch (e) {
        localStorage.removeItem('formParamHypotheken');
      }
    }
  },
  loadParam({ commit }) {
    let param = null;
    if (localStorage.getItem('formParam')) {
      try {
        param = JSON.parse(localStorage.getItem('formParam'));
        if (param.kundenkreis === 1) {
          param.kundenkreis = 0;
        }
        commit('setFormParam', param);
      } catch (e) {
        localStorage.removeItem('formParam');
      }
    }
    if (localStorage.getItem('chartParam')) {
      try {
        param = JSON.parse(localStorage.getItem('chartParam'));
        commit('setChartParam', param);
      } catch (e) {
        localStorage.removeItem('chartParam');
      }
    }
    if (localStorage.getItem('resultParam')) {
      try {
        param = JSON.parse(localStorage.getItem('resultParam'));
        commit('setResultParam', param);
      } catch (e) {
        localStorage.removeItem('resultParam');
      }
    }
  },
};
