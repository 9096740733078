<template>
  <v-app id="app">
    <div
      v-if="!publicPages.includes(currentPath) && authorized"
      class="not-public"
      :class="{
        'scroll-pages': scrollPages.includes($router.currentRoute.value.name),
      }"
    >
      <TopBar
        :drawer="drawer"
        :mini="mini"
        @change-drawer="handleChangeDrawer"
      />
      <Navigation
        :mini="mini"
        :drawer="drawer"
        @change-drawer="handleChangeDrawer"
      />
      <v-main
        :class="{
          'main-content': validatePath,
        }"
      >
        <div class="v-main__wrap">
          <v-container
            fluid
            class="app-content"
            :class="{
              'overflow-visible':
                $router.currentRoute.name === 'chart-generator',
            }"
          >
            <router-view
              :drawerApp="drawer"
              @change-drawer="handleChangeDrawer"
              :key="$route.fullPath"
            />
          </v-container>
          <v-footer class="footer">
            <Footer />
          </v-footer>
        </div>
      </v-main>
    </div>
    <div v-if="publicPages.includes(currentPath)" class="public">
      <v-main
        v-if="
          ['reset-password', 'login', 'forgot-password'].includes(
            $router.currentRoute.value.name
          )
        "
      >
        <div class="v-main__wrap">
          <router-view />
          <v-footer class="fixed-footer">
            <Footer />
          </v-footer>
        </div>
      </v-main>
      <!--      rate-chart-->
      <router-view v-else />
    </div>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import markerSDK from '@marker.io/browser';

import TopBar from '@/components/TopBar/TopBar.vue';
import Navigation from '@/components/Navigation/Navigation.vue';
import Footer from '@/components/Footer/Footer.vue';

import {
  NAMES_PUBLIC_PAGES,
  PUBLIC_PAGES,
  MARKER_IO_IDS,
} from '@/utils/constants';
import { startTimer } from '@/utils/helpers';

export default defineComponent({
  name: 'App',
  components: { TopBar, Footer, Navigation },
  data() {
    return {
      drawer: true,
      mini: false,
      currentPath: this.$router.currentRoute.value.fullPath,
      mainContentPages: [
        '/:producttype/wizard/:id',
        '/:producttype/wizard',
        '/',
      ],
      scrollPages: [
        'bankingProfileReport',
        'bankingProfileNewReport',
        'wizardEditReport',
        'wizard',
        'report',
        'benchmark',
      ] as string[],
      publicPages: PUBLIC_PAGES,
    };
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/isAuthorized',
      authLoading: 'auth/getLoading',
      isAuthorizationExpired: 'auth/isAuthorizationExpired',
      bankDetails: 'bankingProfile/getBankDetails',
    }),
    authorized() {
      return process.env.VUE_APP_IS_DEVELOPMENT === 'true'
        ? true
        : this.isAuthorized;
    },
    validatePath() {
      //eslint-disable-next-line
      return this.$router.currentRoute.value.matched?.[0] ? this.mainContentPages.includes(this.$router.currentRoute.value.matched[0].path) : false;
    },
  },
  watch: {
    isAuthorized(val) {
      this.setTimer(val);
    },
    isAuthorizationExpired(val) {
      this.setTimer(val);
    },
    '$i18n.locale': function (locale) {
      const projectId = locale === 'de' ? MARKER_IO_IDS.de : MARKER_IO_IDS.en;
      this.loadWidget(projectId);
      this.setDocumentTitle(locale);
    },
    $route() {
      (async () => {
        const locale = JSON.parse(
          localStorage.getItem('language') || JSON.stringify('de')
        );
        this.setDocumentTitle(locale);

        if (
          !this.isAuthorized &&
          !(NAMES_PUBLIC_PAGES as any).includes(
            this.$router.currentRoute.value.name
          )
        ) {
          await this.authCheck({
            page: this.$router.currentRoute.value.fullPath,
          });
        }
        if (this.$router.currentRoute.value.name === 'rate-chart') {
          this.currentPath = '/rate-chart';
        } else {
          this.currentPath = this.$router.currentRoute.value.fullPath;
        }
      })();
    },
  },
  methods: {
    ...mapActions({
      authCheck: 'auth/authCheck',
    }),
    setTimer(val: boolean) {
      if (val) {
        const expiresAt = localStorage.getItem('expiresAt') || '';
        if (expiresAt) {
          const expire = JSON.parse(expiresAt);
          const expiresAtTime = Number(
            String(new Date(expire.split('+')[0]).getTime()).slice(0, 10)
          );

          const currentTime = Number(String(Date.now()).slice(0, 10));
          const expireTime = (expiresAtTime - currentTime) * 1000;
          startTimer(expireTime, this.$router.currentRoute.value.fullPath);
        }
      }
    },
    loadWidget(projectId: string) {
      if (
        process.env.NODE_ENV === 'production' &&
        !window.location.origin.includes('dev')
      ) {
        markerSDK.loadWidget({
          project: projectId,
        });
      }
    },
    setDocumentTitle(locale: string) {
      if (!this.$router.currentRoute.value.meta?.title) {
        //@ts-ignore
        document.title = 'Radar';
      }
      if (
        this.$router.currentRoute.value.meta?.title &&
        this.$router.currentRoute.value.name &&
        this.$router.currentRoute.value.name !== 'bankDetails'
      ) {
        //@ts-ignore
        document.title =
          locale === 'de'
            ? this.$router.currentRoute.value.meta?.title
            : this.$router.currentRoute.value.meta?.titleEn
            ? this.$router.currentRoute.value.meta.titleEn
            : this.$router.currentRoute.value.meta?.title;
      }
      if (
        this.bankDetails.anbietername &&
        this.$router.currentRoute.value.name &&
        this.$router.currentRoute.value.name === 'bankDetails'
      ) {
        //@ts-ignore
        document.title =
          locale === 'de'
            ? `Radar - Anbieterprofile ${this.bankDetails.anbietername}`
            : `Radar - Provider Profiles ${this.bankDetails.anbietername}`;
      }
    },
    handleChangeDrawer(val) {
      this.mini = val.mini;
    },
  },
  mounted() {
    const locale = JSON.parse(
      localStorage.getItem('language') || JSON.stringify('de')
    );
    if (locale === 'de') {
      this.setDocumentTitle('de');
      this.loadWidget(MARKER_IO_IDS.de);
    }
  },
});
</script>

<style lang="scss">
.v-main {
  display: flex !important;
  flex: 1 0 auto !important;
  max-width: 100%;
}
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
.dashboard-page {
  height: 100% !important;
}

.main-content {
  & > .v-main__wrap {
    background-color: $main-background;
  }
}

.table_shadow {
  width: 100% !important;
}

.v-data-table__wrapper {
  overflow-y: initial !important;
  overflow-x: initial !important;
}
.public {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $main-background;
}
.not-public {
  display: flex;
  flex-direction: column;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  max-width: 100%;
  position: relative;
  height: 100vh;
  .app-content {
    overflow: hidden;
  }
  .overflow-visible {
    overflow: visible;
  }
  [class~='v-main'] {
    background-color: $main-background;
    height: 100vh;
  }
  [class~='v-main__wrap'] {
    overflow-y: auto;
  }
  .footer {
    overflow: hidden;
  }
}

.scroll-pages {
  .app-content {
    overflow: initial !important;
  }
  [class~='v-main__wrap'] {
    overflow-x: auto;
    background-color: $background;
  }
}

@media screen and (max-width: 376px) {
  body {
    overflow-y: scroll;
  }
}
@media screen and (max-height: 376px) {
  body {
    overflow-y: scroll;
  }
}
@media screen and (max-height: 414px) {
  body {
    overflow-y: scroll;
  }
}
</style>
