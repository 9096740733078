import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_btn_toggle = _resolveComponent("v-btn-toggle")!

  return (_openBlock(), _createBlock(_component_v_btn_toggle, {
    modelValue: _ctx.modelSource,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelSource) = $event)),
    color: "selected",
    mandatory: "",
    class: "languages-selection-btns",
    variant: 
      ['reset-password', 'login', 'forgot-password'].includes(
        _ctx.$router.currentRoute.value.name
      )
        ? 'flat'
        : 'outlined'
    
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_btn, {
        size: "small",
        value: "de"
      }, {
        default: _withCtx(() => [
          _createTextVNode("DE")
        ]),
        _: 1
      }),
      _createVNode(_component_v_btn, {
        size: "small",
        value: "en"
      }, {
        default: _withCtx(() => [
          _createTextVNode("EN")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "variant"]))
}