import { createStore, createLogger } from 'vuex';

import benchmark from './benchmark';
import app from './app';
import wizard from './wizard';
import reports from './reports';
import dateNow from './dateNow';
import baugeld from './baugeld';
import auth from './auth';
import datenabo from './datenabo';
import anbieter from './anbieter';
import chartColors from './chart-colors';
import param from './param';
import result from './result';
import bankingProfile from './bankingProfile';
import interestCharts from './interestCharts';
import specialSubscribers from './special-subscribers';
import wettbewerb from './wettbewerb';

const debug = process.env.NODE_ENV !== 'production';

const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  modules: {
    wizard,
    reports,
    dateNow,
    baugeld,
    auth,
    anbieter,
    chartColors,
    param,
    result,
    specialSubscribers,
    datenabo,
    wettbewerb,
    bankingProfile,
    interestCharts,
    benchmark,
    app,
  },
  actions: {
    resetFestGeld({ commit }) {
      commit('param/resetStateFestGeld');
      commit('result/resetStateFestGeld');
    },
    resetHypotheken({ commit }) {
      commit('param/resetStateHypotheken');
      commit('result/resetStateHypotheken');
    },
    resetStore({ commit }) {
      commit('param/resetState');
      commit('result/resetState');
    },
  },
  plugins,
});
