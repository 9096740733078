import { createRouter, createWebHistory } from 'vue-router';

const Benchmark = () => import('../src/views/Benchmark/Benchmark');
const Wizard = () => import('./views/Wizard/Wizard.vue');
const Dashboard = () => import('@/views/Dashboard/Dashboard');
const Tagesgeld = () => import('@/views/Tagesgeld/Tagesgeld');
const Festgeld = () => import('@/views/Festgeld/Festgeld');
const ReportOverview = () =>
  import('@/views/ReportOverview/ReportOverview.vue');
const Baugeld = () => import('@/views/Baugeld/Baugeld');
const Login = () => import('@/views/Login/Login');
const ForgotPassword = () => import('@/views/ForgotPassword/ForgotPassword');
const ResetPassword = () => import('@/views/ResetPassword/ResetPassword');
const WBBaugeldReports = () =>
  import('@/views/Wettbewerb/views/reports/WBBaugeldReports.vue');
const WBTagesgeldReports = () =>
  import('@/views/Wettbewerb/views/reports/WBTagesgeldReports.vue');
const WBFestgeldReports = () =>
  import('@/views/Wettbewerb/views/reports/WBFestgeldReports.vue');
const SpezialabonnentenTagesgeld = () =>
  import(
    '@/views/Spezialabonnenten/SpezialabonnentenTagesgeld/SpezialabonnentenTagesgeldIndex'
  );
const SpezialabonnentenFestgeld = () =>
  import(
    '@/views/Spezialabonnenten/SpezialabonnentenFestgeld/SpezialabonnentenFestgeldIndex'
  );
const KfwBaugeld = () => import('@/views/Kfw/KfwBaugeld/KfwBaugeldIndex');
const Datenabo = () => import('@/views/Datenabo/Datenabo');
const RateChart = () => import('@/views/RateChart/RateChart');

const NewReportTagesgeld = () =>
  import(
    '@/views/Wettbewerb/views/Tagesgeld/NewReportTagesgeld/NewReportTagesgeld'
  );
const NewReportFestGeld = () =>
  import(
    '@/views/Wettbewerb/views/Festgeld/NewReportFestGeld/NewReportFestGeld'
  );
const NewReportBaugeld = () =>
  import('@/views/Wettbewerb/views/Baugeld/NewReportBaugeld/NewReportBaugeld');
const Report = () => import('@/views/Wettbewerb/views/ReportOverview/Report');
const ChartGenerator = () => import('@/views/ChartGenerator/ChartGenerator');
const AnbieterProfile = () =>
  import('@/views/AnbieterProfile/AnbieterProfile.vue');
const BPBankDetails = () => import('@/views/BPBankDetails/BPBankDetails');

import { hasAccess } from '@/utils/helpers';
import {
  IS_BM_AVAILABLE,
  IS_CREATE_PAGE_AVAILABLE,
  PUBLIC_PAGES,
} from '@/utils/constants';
import { store } from '@/store';
const CreateConfig = () => import('@/views/CreateConfig/CreateConfig.vue');

const app_name = 'Radar';

const routes = [
  {
    path: '/mittelwerte/girokonto',
    name: 'benchmark',
    component: Benchmark,
    meta: {
      permission: ['girokonto'],
      requiresAuth: true,
      panel: 'Averages',
      title: `${app_name} - Girokonto`,
      titleEn: `${app_name} - Checking Account`,
    },
    beforeEnter: (to, from, next) => {
      if (IS_BM_AVAILABLE) {
        next();
      } else {
        next({ name: 'dashboard' });
      }
    },
  },
  {
    path: '/create',
    name: 'createConfig',
    component: CreateConfig,
    beforeEnter: (to, from, next) => {
      if (IS_CREATE_PAGE_AVAILABLE) {
        next();
      } else {
        next({ name: 'dashboard' });
      }
    },
  },
  {
    path: '/banking-profile/bank-details/:id',
    name: 'bankDetails',
    component: BPBankDetails,
    meta: {
      permission: [
        'callMoney.profile',
        'mortgage.profile',
        'timeDeposits.profile',
      ],
      requiresAuth: true,
      title: `${app_name}`,
      title_en: `${app_name}`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/banking-profile',
    name: 'bankingProfileNewReport',
    component: AnbieterProfile,
    meta: {
      permission: [
        'callMoney.profile',
        'mortgage.profile',
        'timeDeposits.profile',
      ],
      requiresAuth: true,
      title: `${app_name} - Anbieterprofile`,
      titleEn: `${app_name} - Provider Profiles`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/:producttype/wizard/:id',
    name: 'wizardEditReport',
    component: Wizard,
    meta: {
      requiresAuth: true,
      title: `${app_name} - Report bearbeiten`,
      analyticsIgnore: true,
    },
  },
  {
    path: '/:producttype/wizard',
    name: 'wizard',
    component: Wizard,
    meta: {
      requiresAuth: true,
      title: `${app_name} - Auswertung erstellen`,
      titleEn: `${app_name} - Create Report`,
      analyticsIgnore: true,
    },
  },
  {
    path: '/chart-generator',
    name: 'chart-generator',
    component: ChartGenerator,
    meta: {
      requiresAuth: true,
      permission: [
        'callMoney.interestCharts',
        'mortgage.interestCharts',
        'timeDeposits.interestCharts',
      ],
      title: `${app_name} - Chart Generator`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/rate-chart/:slug',
    name: 'rate-chart',
    component: RateChart,
    meta: {
      title: `${app_name}`,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: `${app_name} - Dashboard`,
    },
  },
  {
    path: '/:producttype/report/:slug',
    name: 'report',
    component: ReportOverview,
    meta: {
      requiresAuth: true,
      panel: 'Live-Daten',
      title: `${app_name} - Live Daten Details`,
      titleEn: `${app_name} - Live Data Details`,
    },
  },
  {
    path: '/tagesgeld',
    name: 'tagesgeld',
    component: Tagesgeld,
    meta: {
      permission: 'callMoney.liveData',
      requiresAuth: true,
      panel: 'Live-Daten',
      title: `${app_name} - Live Daten Tagesgeld`,
      titleEn: `${app_name} - Live Data Call Money`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/festgeld',
    name: 'festgeld',
    component: Festgeld,
    meta: {
      permission: 'timeDeposits.liveData',
      requiresAuth: true,
      panel: 'Live-Daten',
      title: `${app_name} - Live Daten Festgeld`,
      titleEn: `${app_name} - Live Data Fixed Deposit`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/baugeld',
    name: 'baugeld',
    component: Baugeld,
    meta: {
      permission: 'mortgage.liveData',
      requiresAuth: true,
      panel: 'Live-Daten',
      title: `${app_name} - Live Daten Baugeld`,
      titleEn: `${app_name} - Live Data Mortgage`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/wettbewerb-baugeld',
    name: 'wettbewerb-baugeld',
    component: WBBaugeldReports,
    meta: {
      permission: 'mortgage.competition',
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Baugeld`,
      titleEn: `${app_name} - Historical Data Mortgage`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/wettbewerb-tagesgeld',
    name: 'wettbewerb-tagesgeld',
    component: WBTagesgeldReports,
    meta: {
      permission: 'callMoney.competition',
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Tagesgeld`,
      titleEn: `${app_name} - Historical Data Call Money`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/:producttype/wettbewerb-report/:slug',
    name: 'wettbewerb-report',
    component: Report,
    meta: {
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Wettbewerb Report`,
    },
  },
  {
    path: '/wettbewerb-tagesgeld/new-report',
    name: 'wettbewerb-tagesgeld-new-report',
    component: NewReportTagesgeld,
    meta: {
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Tagesgeld Neue Auswertung`,
      titleEn: `${app_name} - Historical Data Call Money New Report`,
    },
  },
  {
    path: '/wettbewerb-festgeld/new-report',
    name: 'wettbewerb-festgeld-new-report',
    component: NewReportFestGeld,
    meta: {
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Festgeld Neue Auswertung`,
      titleEn: `${app_name} - Historical Data Fixed Deposit New Report`,
    },
  },
  {
    path: '/wettbewerb-baugeld/new-report',
    name: 'wettbewerb-baugeld-new-report',
    component: NewReportBaugeld,
    meta: {
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Baugeld Neue Auswertung`,
      titleEn: `${app_name} - Historical Data Mortgage New Report`,
    },
  },
  {
    path: '/wettbewerb-festgeld',
    name: 'wettbewerb-festgeld',
    component: WBFestgeldReports,
    meta: {
      permission: 'timeDeposits.competition',
      requiresAuth: true,
      panel: 'Historische Daten',
      title: `${app_name} - Historische Daten Festgeld`,
      titleEn: `${app_name} - Historical Data Fixed Deposit`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/spezialabonnenten-tagesgeld',
    name: 'spezialabonnenten-tagesgeld',
    component: SpezialabonnentenTagesgeld,
    meta: {
      permission: 'product.nibc',
      panel: 'NIBC',
      requiresAuth: true,
      title: `${app_name} - NIBC Tagesgeld`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/spezialabonnenten-festgeld',
    name: 'spezialabonnenten-festgeld',
    component: SpezialabonnentenFestgeld,
    meta: {
      permission: 'product.nibc',
      panel: 'NIBC',
      requiresAuth: true,
      title: `${app_name} - NIBC Festgeld`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/kfw-baugeld',
    name: 'kfw-baugeld',
    component: KfwBaugeld,
    meta: {
      permission: 'product.kfw',
      panel: 'KfW',
      requiresAuth: true,
      title: `${app_name} - KFW Baugeld`,
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `${app_name} - Login`,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: `${app_name} - Passwort vergessen`,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: `${app_name} - Passwort zurücksetzen`,
    },
  },
  {
    path: '/datenabo',
    name: 'datenabo',
    component: Datenabo,
    meta: {
      permission: 'product.abo',
      title: '',
    },
    beforeEnter: (to, from, next) => hasAccess(to, from, next),
  },
  { path: '/:catchAll(.*)', redirect: '/dashboard' },
];
const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, from, next) => {
  const pages = [
    'bankDetails',
    'bankingProfileNewReport',
    'chart-generator',
    'dashboard',
    'wettbewerb-baugeld',
    'wettbewerb-tagesgeld',
    'wettbewerb-festgeld',
    'spezialabonnenten-tagesgeld',
    'spezialabonnenten-festgeld',
    'kfw-baugeld',
    'datenabo',
    'login',
  ];
  if (from.name === 'bankingProfileNewReport') {
    store.commit('bankingProfile/clearProfile');
  }
  if (pages.includes(to.name)) {
    store.commit('reports/cancelControllers');
  }
  if (
    ['wizard', 'wizardEditReport', 'tagesgeld', 'festgeld', 'baugeld'].includes(
      from.name
    )
  ) {
    store.commit('reports/cancelControllers');
    store.commit('reports/resetState');
  }
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const locale = JSON.parse(
    localStorage.getItem('language') || JSON.stringify('de')
  );
  if (nearestWithTitle) {
    document.title =
      locale === 'de'
        ? nearestWithTitle.meta?.title
        : nearestWithTitle.meta?.titleEn
        ? nearestWithTitle.meta.titleEn
        : nearestWithTitle.meta?.title;
  }
  next();
});

router.afterEach(async (to, from) => {
  if (!PUBLIC_PAGES.includes(from.fullPath)) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const _paq = (window._paq = window._paq || []);
      //window._vm.$intercom.trackEvent(`Visit ${record.name}`);
      const user = localStorage.getItem('user');
      if (user) {
        const { email } = JSON.parse(user);
        _paq.push(['setUserId', email]);
      }
    }
  }
});

export default router;
