<template>
  <v-row class="pt-3 pb-10" id="appFooter">
    <v-col></v-col>
    <v-col class="d-flex flex-column align-center">
      <span class="fmh-info"
        >© {{ currentYear }}, {{ t('footer.financialConsulting') }}</span
      >
      <v-col class="d-flex justify-center">
        <a class="tab" href="https://www.fmhx.de/" target="_blank">{{
          t('footer.contact')
        }}</a>
        <span>|</span>
        <a class="tab" href="https://www.fmhx.de/impressum" target="_blank">{{
          t('footer.imprint')
        }}</a>
        <span>|</span>
        <a class="tab" href="https://www.fmhx.de/agb" target="_blank">{{
          t('footer.agb')
        }}</a>
        <span>|</span>
        <a class="tab" href="https://www.fmhx.de/datenschutz" target="_blank">{{
          t('footer.privacy')
        }}</a>
        <span>|</span>
        <a class="tab" href="https://www.fmh.de/anb" target="_blank">{{
          t('footer.anb')
        }}</a>
      </v-col>
      <v-col class="d-flex contact flex-column">
        <span>{{ t('footer.financialConsulting') }}</span>
        <span>{{ t('footer.financialConsultingAddress') }}</span>
        <span
          >{{ t('footer.phone') }} 069 9518980 |
          {{ t('footer.fax') }} 06995189818</span
        >
      </v-col>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Footer',
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
});
</script>

<style lang="scss" scoped>
.tab {
  font-family: var(--interFont);
  color: $link-color;
  text-decoration: none;
  padding: 0 10px 0 10px;
}
.contact {
  color: #7f7f7f;
  justify-content: center;
  align-items: center;
  span {
    font-family: var(--interFont);
  }
}
.fmh-info {
  font-family: var(--interFont);
  color: #7f7f7f;
  justify-content: center;
}
</style>
