export default {
  getChartDataTagesgeld(state) {
    return state.chartData;
  },
  getChartDataFestGeld(state) {
    return state.chartDataFestGeld;
  },
  getChartDataHypotheken(state) {
    return state.chartDataHypotheken;
  },
};
