import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const DEFAULT_FORM_PARAM_TAGESGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  anlagebetrag: 10000,
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
};
export const DEFAULT_FORM_PARAM_BAUGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  sollzinsbindung: 10,
  beleihung: 60,
};
export const DEFAULT_FORM_PARAM_FESTGELD = {
  bankname1: '',
  bankname2: '',
  bankname3: '',
  bankname4: '',
  bankname5: '',
  zeitraum: 4,
  zeitraumVon: null,
  zeitraumBis: null,
  kundenkreis: 0,
  vermittlerangebote: { value: 0, selected: false },
  anlagebetrag: 50000,
  anlagedauer: 1,
};
export const DEFAULT_CHART_PARAM_FESTGELD = {
  datasetColor1: 2,
  datasetColor2: 0,
  datasetColor3: 3,
  datasetColor4: 1,
  datasetColor5: 4,
  chartMinValue: true,
  chartMaxValue: true,
  chartAvgValue: true,
  zins1: true,
  zins2: true,
  zins3: true,
  zins4: true,
  zins5: true,
};
export const DEFAULT_CHART_PARAM_BAUGELD = {
  datasetColor1: 2,
  datasetColor2: 0,
  datasetColor3: 3,
  datasetColor4: 1,
  datasetColor5: 4,
  chartMinValue: true,
  chartMaxValue: true,
  chartAvgValue: true,
  zins1: true,
  zins2: true,
  zins3: true,
  zins4: true,
  zins5: true,
};

export function defaultState() {
  return {
    formParam: { ...DEFAULT_FORM_PARAM_TAGESGELD },
    copyFormParam: { ...DEFAULT_FORM_PARAM_TAGESGELD },
    forceUpdate: false,
    formParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
    copyFormParamHypotheken: { ...DEFAULT_FORM_PARAM_BAUGELD },
    formParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
    copyFormParamFestGeld: { ...DEFAULT_FORM_PARAM_FESTGELD },
    chartParamFestGeld: { ...DEFAULT_CHART_PARAM_FESTGELD },
    chartParamHypotheken: { ...DEFAULT_CHART_PARAM_BAUGELD },
    chartParam: {
      datasetColor1: 2,
      datasetColor2: 0,
      datasetColor3: 3,
      datasetColor4: 1,
      datasetColor5: 4,
      chartMinValue: true,
      chartMaxValue: true,
      chartAvgValue: true,
      zins1: true,
      zins2: true,
      zins3: true,
      zins4: true,
      zins5: true,
    },
    resultParam: {
      showResult: false,
    },
  };
}

const state = defaultState();

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
