import { defaultState } from './index';

export default {
  setChartDataFestGeld(state, payload) {
    state.chartDataFestGeld = { show: true, data: payload || [] };
    localStorage.setItem(
      'chartDataFestGeld',
      JSON.stringify(state.chartDataFestGeld)
    );
  },
  setChartDataFestGeldFromLocalStorage(state, payload) {
    state.chartDataFestGeld = payload;
  },
  setChartDataHypothekenFromLocalStorage(state, payload) {
    state.chartDataHypotheken = payload;
  },
  setChartDataHypotheken(state, payload) {
    state.chartDataHypotheken = {
      ...state.chartDataHypotheken,
      show: true,
      data: payload || [],
    };
    localStorage.setItem(
      'chartDataHypotheken',
      JSON.stringify(state.chartDataHypotheken)
    );
  },
  setChartData(state, payload) {
    state.chartData = { show: true, data: payload || [] };
    localStorage.setItem('chartData', JSON.stringify(state.chartData));
  },
  resetStateHypotheken(state) {
    state.chartDataHypotheken = {
      show: false,
      data: [],
    };
    localStorage.removeItem('chartDataHypotheken');
  },
  resetStateFestGeld(state) {
    state.chartDataFestGeld = {
      show: false,
      data: [],
    };
    localStorage.removeItem('chartDataFestGeld');
  },
  resetStateTagesgeld(state) {
    state.chartData = {
      show: false,
      data: [],
    };
    localStorage.removeItem('resultParam');
  },
  resetState(state) {
    Object.assign(state, defaultState());
    localStorage.removeItem('chartData');
  },
};
