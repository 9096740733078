import { getField } from 'vuex-map-fields';

export default {
  getField,
  getLoading(state) {
    return state.loading;
  },
  getForceUpdate(state) {
    return state.forceUpdate;
  },
  getFormParam(state) {
    return state.formParam;
  },
  getCopyFormParam(state) {
    return state.copyFormParam;
  },
  getFormParamHypotheken(state) {
    return state.formParamHypotheken;
  },
  getCopyFormParamHypotheken(state) {
    return state.copyFormParamHypotheken;
  },
  getFormParamFestgeld(state) {
    return state.formParamFestGeld;
  },
  getCopyFormParamFestgeld(state) {
    return state.copyFormParamFestGeld;
  },
  getChartParamHypotheken(state) {
    return state.chartParamHypotheken;
  },
  getChartParamFestgeld(state) {
    return state.chartParamFestGeld;
  },
  getChartParam(state) {
    return state.chartParam;
  },
  getResultParam(state) {
    return state.resultParam;
  },
  getFieldValue: (state) => (path) => {
    let paths = path.split('.');
    if (paths.length === 2) {
      return state[paths[0]][paths[1]];
    } else {
      return state[paths[0]];
    }
  },
};
